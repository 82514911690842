@use "@scss/responsives" as responsives;
@use '@scss/colors' as colors;

.stuffBetaGamaMint {
    color: colors.$white;

    h1 {
        margin-bottom: 2rem;
    }

    .scratcherStack {
        margin: 3rem 0;

        text-align: center;

        img {
            max-width: 22rem;
            width: 100%;
        }
    }
}

@include responsives.resolution__mobile {
    .stuffBetaGamaMint {
        h1 {
            font-size: 2rem;
        }

        p {
            font-size: 0.8rem;
        }
    }
}
