.mintDetail {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;

    .imageContainer {
        text-align: center;

        img {
            max-width: 20rem;
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;

        a {
            padding: 0.25rem 1rem;
            font-size: 1rem;
        }

        p {
            margin-top: 0.2rem;
            margin-right: 1rem;
        }
    }
}
