@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

.timelineCard__container {
    background-color: white;
    border: 4px solid black;
    padding: 2.5rem;
    margin: auto;
    max-width: 80%;
    margin-bottom: 4rem;

    @include responsives.resolution__mobile {
        margin-bottom: 2rem;
    }

    img {
        max-width: 100%;
    }

    h3 {
        padding-bottom: 1.5rem;
    }
}

@include responsives.resolution__laptop__s {
    .timelineCard__container {
        padding: 2rem;
        max-width: 95%;
    }
}

@include responsives.resolution__tablet {
    .timelineCard__container {
        padding: 1rem;
        max-width: 100%;
    }
}
