$courierRegular: "courier-regular";
$courierBold: "courier-bold";
$amarilloUsaf: "Amarillo-USAF";

$h1-size: 3rem;
$h2-size: 2.5rem;
$h3-size: 2rem;
$h4-size: 1.3rem;
$h5-size: 1rem;

$h1-size-mobile: 2.5rem;
$h2-size-mobile: 2rem;
$h3-size-mobile: 1.5rem;
$h4-size-mobile: 1rem;
$h5-size-mobile: 0.8rem;
