@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.boxDescription {
    padding: 2rem 1.4rem 0 1.4rem;

    @include responsives.resolution__mobile {
        padding: 1rem 0 0 0;
    }

    p {
        @include responsives.resolution__mobile {
            font-size: 0.8rem;
        }

        > span {
            font-family: fonts.$courierBold;
        }
    }
}
