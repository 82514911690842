@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.legend__container {
    @include mixins.scrollbar(0.3rem, colors.$space-station-scroll-primary, colors.$space-station-scroll-secondary);
    @include mixins.flex_container(column, flex-start, flex-start);
    width: 100%;
    height: 50%;
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    padding-bottom: 1.5rem;
    background-image: url(~@assets/images/starmap/legend_frame.png);
    background-size: 100% 100%;
    font-family: fonts.$amarilloUsaf;

    h2 {
        width: fit-content;
        font-weight: 200;
        color: colors.$white;
        font-size: 2rem;
        margin-bottom: 1rem;
        border-bottom: 0.13rem solid white;
    }
}

.legend__body {
    width: 100%;
    overflow-y: auto;
}

.item {
    @include mixins.flex_container(row, flex-start, center);
    margin-bottom: 0.8rem;

    &__image {
        width: 2.7rem;
        margin-right: 0.5rem;
    }

    &__name {
        font-family: fonts.$amarilloUsaf;
        font-size: 1.5rem;
        color: colors.$blue-space;
    }
}

@include responsives.resolution__desktop__m {
    .legend__container {
        padding: 2rem 1.5rem 2rem 1.3rem;
    }

    .item {
        &__image {
            width: 2.5rem;
            margin-right: 0.3rem;
        }

        &__name {
            font-size: 1.3rem;
        }
    }
}
