@use '@scss/buttons' as btn;
@use "@scss/responsives" as responsives;
@use '@scss/colors' as colors;
@use '@scss/mixins' as mixins;

.combobox {
    display: none;
}

@include responsives.resolution__laptop__m {
    .combobox {
        display: block;
        position: relative;

        &_select {
            @include btn.btn;
            @include btn.quaternary;
            @include mixins.flex_container(row, center, center);
            text-align: start;
            margin: 1rem 0;
            padding-right: 4rem;
            background-color: white;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            ::-ms-expand {
                display: none;
            }
        }

        &_icon {
            @include mixins.flex_container(row, center, center);
            position: absolute;
            right: 0;
            transform: translate(-30%, 95%);
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 2rem;
            background-color: colors.$blue-space;
            user-select: none;
            pointer-events: none;

            .arrow {
                color: white;
                font-size: 1.1rem;
            }
        }
    }
}
