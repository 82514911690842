@use "@scss/responsives" as responsives;
@use '@scss/fonts' as fonts;

.boxRockets {
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 1rem;

    .boxRockets__rocket {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 20%;

        @include responsives.resolution__tablet {
            flex-basis: 25%;
        }

        @include responsives.resolution__mobile {
            flex-basis: 33%;
        }

        span {
            font-family: fonts.$courierBold;

            @include responsives.resolution__laptop__s {
                font-size: 0.7rem;
            }

            @include responsives.resolution__tablet {
                font-size: 0.8rem;
            }

            @include responsives.resolution__mobile {
                font-size: 0.6rem;
            }
        }

        img {
            max-width: 100%;
        }
    }
}
