@use "@scss/responsives" as responsives;

.PlanetMintSoldOut {
    background-image: url("./../../assets/images/utils/galaxyBackground.jpg");
    background-size: contain;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;

    img {
        margin: 1rem 0;
        max-width: 25rem;

        @include responsives.resolution__mobile {
            max-width: 20rem;
        }
    }

    a {
        width: 15rem;
    }
}
