@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.confirmAndMint {
    p {
        margin: 1.5rem 0;
    }

    .titleCheckbox {
        display: flex;
        gap: 1rem;
    }

    .confirmTaC {
        margin: 0;

        a {
            font-family: fonts.$amarilloUsaf;
            font-size: 1.35rem;
            color: colors.$red;
            text-decoration: underline;
        }
    }

    .checkboxIcon {
        width: 1.5625rem;
        margin-top: -0.75rem;
        margin-left: 0.6875rem;
    }

    .bottom {
        text-align: center;

        p {
            margin-top: 2.5rem;
            margin-bottom: 1.2rem;
            font-family: fonts.$amarilloUsaf;
            text-decoration: underline;
            font-size: 1.5rem;

            .ethSymbol {
                font-family: Arial, Helvetica, sans-serif;
            }
        }

        button {
            margin-top: 0rem;
        }
    }

    .mobileOnly {
        display: none;
    }
}

@include responsives.resolution__mobile {
    .confirmAndMint {
        .confirmTaC a {
            font-size: 1.75rem !important;
        }

        .checkbox {
            width: 40px !important;
            height: 40px !important;
            border-width: 4px !important;
        }

        .mobileOnly {
            display: block;
        }
    }
}
