.mintOptionSelection {
    a {
        margin-bottom: 1.5rem;
        width: 19.5rem;
    }

    .galaxy {
        img {
            max-width: 22rem;
            margin-bottom: 3rem;
        }
    }
}
