@use '@scss/buttons' as btn;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.goback__mobile {
    display: none;
}

@include responsives.resolution__laptop__m {
    .goback__mobile {
        @include mixins.flex_container(row, center, center);
        @include btn.btn;
        @include btn.quaternary;
        margin-bottom: 2rem;

        .icon {
            font-size: 1rem;
            margin-right: 0.3rem;
        }
    }
}
