@use '@scss/colors' as colors;

.physicalScratcher {
    color: colors.$white;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        font-weight: 200;
    }
}
