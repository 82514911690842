@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .section {
        width: 100%;
        display: flex;
        flex-direction: column;

        .itemsContainer {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin-bottom: 2rem;
            gap: 1rem;
        }
    }

    .button {
        margin-top: 2rem;
    }
}

@include responsives.resolution__laptop__m {
    .container {
        padding-right: 1.5rem;

        .section {
            .itemsContainer {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                margin-bottom: 2rem;
            }
        }
    }
}
