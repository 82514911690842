@use '@scss/colors' as colors;
@use "@scss/responsives" as responsives;
@use "@scss/mixins" as mixins;

.buttonsSection {
    position: relative;
    width: 100%;

    .tryAgainButton {
        background-color: colors.$white;
        color: colors.$red;
        border-color: colors.$white;
        font-size: 2rem;
        transform: rotate(-20deg);
        position: absolute;
        right: -12%;
        bottom: 20%;

        @include responsives.resolution__laptop__l {
            right: -9%;
        }

        @include responsives.resolution__laptop__m {
            right: 0%;
        }

        @include responsives.resolution__tablet {
            font-size: 1.5rem;
        }
    }

    @include responsives.resolution__mobile {
        @include mixins.flex_container(column, center, center);
        .tryAgainButton {
            position: static;
            margin-top: 2rem;
        }
    }
}
