@use "@scss/fonts" as fonts;
@use "@scss/buttons" as btn;
@use '@scss/colors' as colors;

.primary {
    @include btn.btn;
    @include btn.primary;
    margin-top: 3rem;
}

.modalContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;

    .modal {
        width: 100%;
        max-width: 57rem;
        background-color: #e0e9f0;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1000;
        margin-top: 4.1rem;
        position: absolute;
        border: 0.063rem solid colors.$light-gray;

        span {
            font-family: fonts.$courierBold;
            font-size: 1.2rem;
        }

        h1 {
            font-family: fonts.$amarilloUsaf;
            font-size: fonts.$h1-size;
        }

        p {
            margin-top: 3rem;
            font-size: 1.1rem;
            font-family: fonts.$courierRegular;
            text-align: center;
        }

        > .header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            > img {
                cursor: pointer;
            }
        }

        .form {
            width: 100%;
            display: flex;
            flex-direction: column;

            .userInfo {
                width: 100%;
                display: flex;
                justify-content: space-between;

                > .formItem {
                    width: 47%;
                }
            }

            .formItem {
                margin-top: 2rem;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            input,
            textarea {
                margin-top: 1rem;
                width: 100%;
                border: none !important;
                border-radius: 0.438rem;
                padding: 1rem;
                font-size: 1.1rem;
                font-family: fonts.$courierRegular !important;
            }

            input {
                height: 3rem;
            }

            textarea {
                height: 6rem;
            }

            button {
                width: 7rem;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .modalContainer {
        .modal {
            padding: 2rem;

            h1 {
                font-size: 1.875rem;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .primary {
        margin-top: 1rem;
        font-size: 0.9rem;
    }
    .modalContainer {
        .modal {
            max-width: 100%;
            padding: 1.5srem;
            margin-top: 0rem;
            height: 100%;
            width: 100%;
            justify-content: flex-start;
            h1 {
                text-align: center;
            }

            span {
                font-family: fonts.$courierBold;
                font-size: 0.9rem;
            }

            .form {
                margin: 1rem 0;
                .userInfo {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    > .formItem {
                        width: 100%;
                    }
                }
                .formItem {
                    margin-top: 1rem;
                }

                input,
                textarea {
                    margin-top: 0.5rem;
                    width: 100%;
                    border: none !important;
                    border-radius: 0.438rem;
                    padding: 0.5rem;
                    font-size: 0.9rem;
                    font-family: fonts.$courierRegular !important;
                }

                input {
                    height: 2rem;
                }

                textarea {
                    height: 5rem;
                }
            }
        }
    }
}
