@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.galaxyZones {
    @include mixins.flex_container(row, center, center);
    position: relative;
    width: 50rem;
    height: 50rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;

        &:nth-child(2) {
            z-index: 2;
        }

        &:nth-child(6) {
            z-index: 3;
        }

        &:nth-child(8) {
            z-index: 2;
        }
    }

    .zone1,
    .zone2,
    .zone3,
    .zone4,
    .zone5,
    .zone6,
    .zone7,
    .zone8 {
        position: absolute;
        z-index: 1;
    }

    .zone1 {
        right: 7rem;
        top: 8rem;
        width: 9rem;
        height: 12rem;
        border-radius: 0 6rem 2rem 6rem;
    }

    .zone2 {
        left: 21rem;
        top: 13.5rem;
        width: 14rem;
        height: 14rem;
        border-radius: 12rem 12rem 4rem 4rem;
    }

    .zone3 {
        left: 7rem;
        top: 11rem;
        width: 9rem;
        height: 12rem;
        border-radius: 6rem 0 4rem 4rem;
    }

    .zone4 {
        left: 17rem;
        top: 6.5rem;
        width: 11.5rem;
        height: 7.5rem;
        border-radius: 2rem 2rem 5rem 3rem;
    }

    .zone5 {
        right: 7.5rem;
        bottom: 10.5rem;
        width: 7.5rem;
        height: 11rem;
        border-radius: 2rem 2rem 5rem 3rem;
    }

    .zone6 {
        left: 22rem;
        bottom: 4rem;
        width: 12.5rem;
        height: 9.5rem;
        border-radius: 6rem 6rem 2rem 2rem;
    }

    .zone7 {
        left: 8rem;
        bottom: 9rem;
        width: 8rem;
        height: 10rem;
        border-radius: 2rem 2rem 0 6rem;
    }

    .zone8 {
        left: 17.5rem;
        bottom: 12rem;
        width: 10rem;
        height: 10rem;
        border-radius: 2rem 2rem 6rem 6rem;
    }
}

@include responsives.resolution__laptop__m {
    .galaxyZones {
        height: 50vw;

        .zone1 {
            right: 11.5rem;
            top: 6rem;
            width: 7.5rem;
            height: 9rem;
        }

        .zone2 {
            left: 22rem;
            top: 9.5rem;
            width: 11rem;
            height: 11rem;
        }

        .zone3 {
            left: 11rem;
            top: 8rem;
            width: 8rem;
            height: 9rem;
        }

        .zone4 {
            left: 19rem;
            top: 4.5rem;
            width: 9rem;
            height: 6rem;
        }

        .zone5 {
            right: 12rem;
            bottom: 7.5rem;
            width: 6rem;
            height: 8.5rem;
        }

        .zone6 {
            left: 22.5rem;
            bottom: 3rem;
            width: 10rem;
            height: 7rem;
        }

        .zone7 {
            left: 11.5rem;
            bottom: 6.5rem;
            width: 7rem;
            height: 7rem;
        }

        .zone8 {
            left: 19.5rem;
            bottom: 9rem;
            width: 8rem;
            height: 7rem;
        }
    }
}

@include responsives.resolution__laptop__s {
    .galaxyZones {
        .zone1 {
            right: 15rem;
            top: 4.5rem;
            width: 5rem;
            height: 5.5rem;
        }

        .zone2 {
            left: 22rem;
            top: 6rem;
            width: 7.5rem;
            height: 8rem;
        }

        .zone3 {
            left: 14.5rem;
            top: 5.5rem;
            width: 5rem;
            height: 6.5rem;
        }

        .zone4 {
            left: 20rem;
            top: 3rem;
            width: 6.5rem;
            height: 4rem;
        }

        .zone5 {
            right: 15rem;
            bottom: 5rem;
            width: 4rem;
            height: 6rem;
        }

        .zone6 {
            left: 22.5rem;
            bottom: 2rem;
            width: 7rem;
            height: 5rem;
        }

        .zone7 {
            left: 15rem;
            bottom: 4.5rem;
            width: 4.5rem;
            height: 5rem;
        }

        .zone8 {
            left: 20.5rem;
            bottom: 6rem;
            width: 5rem;
            height: 5rem;
        }
    }
}

@include responsives.resolution__tablet {
    .galaxyZones {
        margin-top: -2rem;
        height: 94vw;

        .zone1 {
            right: 7rem;
            top: 7.5rem;
            width: 8rem;
            height: 10.5rem;
        }

        .zone2 {
            left: 19.5rem;
            top: 12rem;
            width: 13rem;
            height: 13rem;
        }

        .zone3 {
            left: 7rem;
            top: 10rem;
            width: 8rem;
            height: 10.5rem;
        }

        .zone4 {
            left: 15.5rem;
            top: 5.5rem;
            width: 11rem;
            height: 7rem;
        }

        .zone5 {
            right: 7rem;
            bottom: 9rem;
            width: 7rem;
            height: 10rem;
        }

        .zone6 {
            left: 20rem;
            bottom: 4rem;
            width: 12rem;
            height: 8rem;
        }

        .zone7 {
            left: 7rem;
            bottom: 8rem;
            width: 8rem;
            height: 9rem;
        }

        .zone8 {
            left: 16.5rem;
            bottom: 11rem;
            width: 9rem;
            height: 8.5rem;
        }
    }
}

@include responsives.resolution__mobile {
    .galaxyZones {
        margin-top: 0;
        .zone1 {
            right: 2.5rem;
            top: 4rem;
            width: 5rem;
            height: 5rem;
        }

        .zone2 {
            left: 9rem;
            top: 6rem;
            width: 7rem;
            height: 6.5rem;
        }

        .zone3 {
            left: 3rem;
            top: 5rem;
            width: 4.5rem;
            height: 5.5rem;
        }

        .zone4 {
            left: 7.5rem;
            top: 3rem;
            width: 5.5rem;
            height: 3.5rem;
        }

        .zone5 {
            right: 3rem;
            bottom: 4.5rem;
            width: 4rem;
            height: 5rem;
        }

        .zone6 {
            left: 9.5rem;
            bottom: 2rem;
            width: 6.5rem;
            height: 4.5rem;
        }

        .zone7 {
            left: 3.5rem;
            bottom: 4rem;
            width: 3.5rem;
            height: 4.5rem;
        }

        .zone8 {
            left: 8rem;
            bottom: 5.8rem;
            width: 4.5rem;
            height: 4.5rem;
        }
    }
}
