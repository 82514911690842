@use "@scss/responsives" as responsives;

.requiredReadingAndViewing__container {
    margin-bottom: 8rem;

    .imageContainer {
        margin-top: 2rem;

        display: flex;
        gap: 2rem;

        .marsRocks {
            flex: 1 0 60.5%;
        }

        h5 {
            margin-top: 1rem;
        }

        .buttonContainer {
            display: flex;
            justify-content: space-between;
            margin-top: 2rem;

            a {
                width: 10.5rem;
            }
        }
    }
}

@include responsives.resolution__laptop__s {
    .requiredReadingAndViewing__container {
        .imageContainer {
            flex-direction: column;
        }
    }
}
