@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;

.mint {
    text-align: left;

    h1 {
        margin-bottom: 2rem;
    }

    p {
        font-size: 1.3rem;
    }

    .tokenContainer {
        margin: 3rem 0;
        display: flex;
        justify-content: center;
        gap: 4rem;

        img {
            margin-top: 1rem;
            max-width: 20rem;
        }

        p {
            margin-top: 1rem;
        }
    }
}
