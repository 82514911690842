@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    span {
        font-family: fonts.$courierBold;
        font-size: 1.1rem;
        margin-bottom: 2rem;
        width: 100%;

        @include responsives.resolution__mobile {
            font-size: 0.8rem;
        }
    }
}

.container__rockets {
    width: 5.5rem;
    margin: 0.3rem;
    padding: 0 0.3rem;
    position: relative;

    img {
        max-width: 4.9375rem;
    }

    button {
        position: absolute;
        border: none;
        cursor: pointer;
        background-color: transparent;
        z-index: 2;
    }

    .nose {
        top: 0;
        width: 1.6rem;
        height: 1.8rem;
        margin-left: 1.45rem;

        &:hover {
            background: url("../../../../../../assets/images/components/hover/nose.png");
            background-repeat: no-repeat;
            background-position: 39% 0%;
            background-size: 290%;
            opacity: 0.5;
        }
    }

    .body {
        top: 1.85rem;
        width: 2rem;
        height: 2.8rem;
        margin-left: 1.2rem;

        &:hover {
            background: url("../../../../../../assets/images/components/hover/body.png");
            background-repeat: no-repeat;
            background-position: 36% 32%;
            background-size: 235%;
            opacity: 0.5;
        }
    }

    .tail {
        top: 4.75rem;
        width: 5rem;
        height: 3.7rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;

        &:hover {
            background: url("../../../../../../assets/images/components/hover/tail.png");
            background-repeat: no-repeat;
            background-position: 70% 98%;
            background-size: 95%;
            opacity: 0.5;
        }
    }
}
