.timelineDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .timelineDetail__image {
        display: flex;
        flex-direction: row;
        justify-content: center;

        img {
            margin: 4rem 0 2rem 0;
            width: 40rem;
            max-width: 100%;
        }
    }
}
