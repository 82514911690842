@use '@scss/buttons' as btn;
@use '@scss/colors' as colors;

.button {
    @include btn.btn;
    @include btn.primary;
    display: inline-block;

    &.disabled {
        border-color: #e8838b;
        background-color: #e8838b;
        pointer-events: none;
    }
}
