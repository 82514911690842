@use "@scss/colors" as colors;
@use '@scss/buttons' as btn;
@use '@scss/fonts' as fonts;

.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    h2 {
        margin-bottom: 2rem;
        font-family: fonts.$amarilloUsaf;
    }

    h1 {
        font-size: fonts.$h1-size;
    }

    h2 {
        font-size: fonts.$h2-size;
    }

    > div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > img {
            margin-bottom: 1.5rem;
        }

        > span {
            margin-bottom: 2rem;
            font-family: fonts.$courierRegular;
        }

        > p {
            max-width: 70rem;
            font-family: fonts.$courierRegular;
            line-height: 1.75rem;
            font-size: 1.118rem;
        }
    }

    .requireView {
        padding-top: 2rem;
        width: 100%;

        .primary {
            @include btn.btn;
            @include btn.primary;
            align-self: center;
            margin-top: 2rem;
        }

        > p {
            font-family: fonts.$courierRegular;
            font-size: 1.188rem;
            margin-bottom: 1.5rem;

            a {
                font-family: fonts.$courierBold;
                color: colors.$blue;
                text-decoration: underline;
            }
        }

        > div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .videoContainer {
                iframe {
                    width: 20rem;
                    height: 12.5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        padding-right: 1.5rem;

        > div {
            padding-bottom: 2rem;

            > h1 {
                margin-bottom: 1.5rem;
            }

            > span {
                margin-bottom: 1.5rem;
            }
        }

        .requireView {
            > div {
                > .videoContainer {
                    margin: 2rem 0;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        > div {
            > img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }

            > h1 {
                margin-bottom: 1.5rem;
                font-family: fonts.$amarilloUsaf;
                font-size: 1.875rem;
            }

            > p {
                max-width: 70rem;
                font-family: fonts.$courierRegular;
                font-size: 0.75rem;
                line-height: 1.75rem;
            }
        }

        .requireView {
            > p {
                font-family: fonts.$courierRegular;
                font-size: 1.188rem;
                margin-bottom: 0.875rem;
            }

            > h2 {
                font-family: fonts.$amarilloUsaf;
                font-size: 1.875rem;
                margin-bottom: 2rem;
            }

            div {
                > .videoContainer {
                    width: 100%;
                    height: 28rem;
                    margin: 2rem auto;

                    > iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .container {
        > div {
            > img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }

        .requireView {
            > div {
                > .videoContainer {
                    width: 100%;
                    height: 19rem;
                    margin: 2rem auto;

                    > iframe {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
