@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.phaseIIHero {
    margin: 4rem 0 6rem 0;
    flex-direction: row;
    display: flex;
    justify-content: center;

    .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: left;

        > p {
            max-width: 100%;
            line-height: 1.75rem;
            font-size: 1.125rem;
            font-family: fonts.$courierRegular;
        }

        > iframe {
            padding-top: 2rem;
            margin-bottom: 2rem;
            height: 500px;
        }
    }

    .buttonContainer {
        margin-top: 3rem;
    }
}

@include responsives.resolution__laptop__s {
    .phaseIIHero {
        flex-direction: column;

        .textContainer {
            padding-left: 0;

            div {
                text-align: left;
            }
        }
    }
}
