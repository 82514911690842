@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: black;
    text-decoration: none;
}

img {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

html,
body {
    width: 100%;
    height: 100%;
    background-color: colors.$background;
    scroll-behavior: smooth;
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

textarea:focus,
input:focus {
    outline: none;
}

h1 {
    font-family: "Amarillo-USAF";
    font-size: 3rem;
}

/* Fonts */
@font-face {
    font-family: "Amarillo-USAF";
    src: local("amarurgt"), url(./assets/fonts/amarurgt.ttf) format("TrueType");
    font-display: swap;
}

@font-face {
    font-family: "courier-regular";
    src: local("courier-new"), url(./assets/fonts/courier-new.ttf) format("TrueType");
    font-display: swap;
}

@font-face {
    font-family: "courier-bold";
    src: local("courier-new-bold"), url(./assets/fonts/courier-new-bold.ttf) format("TrueType");
    font-display: swap;
}

hr {
    width: 100%;
    height: 0.25rem;
    color: #797979;
    background-color: #797979;
    border: none;
}

div {
    .addeventatc {
        color: #ffffff !important;
        background-color: #ed1d27;
        border: 0.2rem solid #ed1d27;
        padding: 0.5rem 1rem;
        font-family: "Amarillo-USAF";
        font-size: 1.3rem;
        border-radius: 2rem;
        box-shadow: none !important;

        > .addeventatc_icon {
            display: none;
        }

        &:hover {
            color: #ed1d27 !important;
            background-color: #ffffff;
            border: 0.2rem solid #ffffff;
            padding: 0.5rem 1rem;
            font-family: "Amarillo-USAF";
            font-size: 1.3rem;
            border-radius: 2rem;
            box-shadow: none !important;
        }
    }
}

iframe {
    border: 0;
}

p {
    font-size: 1.125rem;
    font-family: fonts.$courierRegular;
}
