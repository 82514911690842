@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.nft {
    @include mixins.flex_container(column, center, center);
    width: 100%;

    &__image {
        width: 100%;
    }

    &__name {
        width: 69%;
    }
}

@include responsives.resolution__tablet {
    .nft {
        &__name {
            font-size: 0.9rem;
        }
    }
}

@include responsives.resolution__mobile {
    .nft {
        &__name {
            font-size: 1.2rem;
        }
    }
}
