@use "@scss/responsives" as responsives;

.planetMint {
    color: #ffffff;
    background-image: url("./../../assets/images/utils/galaxyBackground.jpg");
    background-size: contain;
    min-height: 90vh;

    p {
        margin: 2rem 0;
    }
}

@include responsives.resolution__mobile {
    .planetMint h1 {
        font-size: 2.3rem;
    }
}
