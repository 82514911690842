@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/buttons' as btn;
@use '@scss/mixins' as mixins;

.body {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 100%;

    li {
        list-style: none;
    }

    &__stars {
        @include mixins.flex_container(column, flex-start, center);
        max-width: 50rem;
        width: 100%;
        height: auto;
        margin-top: 7rem;
        padding-top: 1rem;

        h2 {
            width: 100%;
            font-weight: 200;
            color: colors.$white;
            font-size: 2.5rem;
            margin-bottom: 2rem;

            hr {
                background-color: colors.$white;
                height: 0.1rem;
            }
        }

        &__category {
            width: 100%;
            margin-bottom: 2rem;
            color: colors.$white;

            > p {
                font-style: italic;
                margin-bottom: 1rem;
                text-transform: capitalize;
            }

            .grid {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;

                p {
                    display: none;
                }
            }
        }

        &__item {
            outline: none;
            color: colors.$white;
            text-align: center;

            img {
                width: 6rem;
            }
        }
    }
}

.scrollto__planet {
    display: none;
}

@include responsives.resolution__desktop__m {
    .body {
        &__stars {
            width: 50%;
        }

        h2 {
            font-size: 1.7rem;
        }
    }
}

@include responsives.resolution__laptop__m {
    .body {
        @include mixins.flex_container(column, center, center);
        width: 100%;

        &__stars {
            width: 100%;
            margin-top: 2rem;
            padding: 0;

            &__category {
                width: 100%;
                margin-bottom: 2rem;
                color: colors.$white;

                > p {
                    font-weight: bold;
                }

                .grid {
                    > div {
                        @include mixins.flex_container(column, center, center);
                        width: 10rem;
                    }

                    p {
                        display: block;
                        text-transform: capitalize;
                        font-size: 0.9rem;
                        margin: 0;

                        &:last-child {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    .scrollto__planet {
        @include btn.btn;
        @include btn.primary;
        display: block;
    }
}
