$red: #ed1d27;
$black: #000000;
$black-alpha: #00000081;
$white: #ffffff;
$blue: #0977be;
$blue-space: #2571c7;
$background: #e0e9f0;
$transparent: transparent;
$disabled-primary: #e8838b;
$disabled-secondary: #9d9d9d;
$medium-gray: #797979;
$timeline-gray: #e0e0e0;
$backButton-gray: #9e9e9e;
$gray: #a9a8a9;
$light-gray: #c1c2c4;
$cell-grey: #efefef;
$cell-red: #ec1f24;
$blue-medium: #0977be;
$yellow: #f3d460;
$dark-violet: #3a4a91;
$green: #09ff00;
$green-matte: #6a733b;
$space-station-scroll-primary: #535353;
$space-station-scroll-secondary: #252525;
