@use '@scss/fonts' as fonts;

.notAuthorized {
    background-image: url("./../../assets/images/utils/galaxyBackground.jpg");
    background-size: cover;
    background-position: center;
    color: #ffffff;
    min-height: 90vh;
    padding-bottom: 2rem;

    > div {
        text-align: center;
        margin: 0 auto;
        max-width: 40rem;
    }

    p {
        text-align: left;
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    a {
        margin: 2rem 0;
        padding: 0.5rem 2.5rem;
    }

    .top {
        img {
            max-width: 20rem;
            margin: 10rem 0 3rem 0;
        }
    }

    .customsAndPlanetaryProtection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 3rem;

        p {
            font-family: fonts.$courierBold;
        }

        img {
            max-width: 8rem;
        }
    }
}
