.stuffOptionSelection {
    a {
        margin-bottom: 1.5rem;
        width: 19.5rem;
    }

    img {
        max-width: 14rem;
        margin-top: 2rem;
        margin-bottom: 5.65rem;
    }
}
