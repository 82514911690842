@use '@scss/buttons' as btn;
@use '@scss/colors' as colors;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.galaxy__title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    font-weight: 200;
    color: colors.$white;
}

.galaxy__explore_desktop,
.galaxy__explore_mobile {
    @include btn.btn;
    @include btn.primary;
    margin-bottom: 2rem;
}

.galaxy__image {
    max-width: 40rem;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
}

.galaxy__explore_mobile {
    display: none;
}

@include responsives.resolution__laptop__m {
    .galaxy__explore_desktop {
        display: none;
    }

    .galaxy__image {
        padding: 0 1.5rem;
        margin-bottom: 3rem;
    }

    .galaxy__explore_mobile {
        display: block;
    }
}
