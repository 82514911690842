@use '@scss/buttons' as btn;
@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;

.primary {
    @include btn.btn;
    @include btn.secondary;
}

.navbarContainer {
    position: fixed;
    z-index: 100;
    width: 100%;

    .optionsMobile {
        display: none;
    }

    .container {
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2rem;
        background-color: colors.$background;
        filter: drop-shadow(0 0.05rem 0.2rem colors.$black-alpha);

        .bars,
        .connectButtonMobile {
            display: none;
        }
    }

    a {
        img {
            max-width: 33.5rem;
        }
    }

    .content {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        .active {
            color: colors.$black;
        }

        .disabled {
            color: colors.$medium-gray;

            &:hover {
                transition: all 0.2s;
                color: colors.$black;
            }
        }

        a {
            display: flex;
            align-items: center;
            font-family: fonts.$amarilloUsaf;
            cursor: pointer;
            font-size: 1.3rem;

            img {
                max-width: 28rem;
            }
        }
    }

    .pushToLeft {
        margin-left: auto;
    }
}

@media screen and (max-width: 1366px) {
    .navbarContainer {
        .container > a > img {
            a {
                img {
                    max-width: 25rem;
                }
            }
        }
    }

    .content {
        width: 55%;
    }

    .content > a {
        font-size: 1rem;
    }

    .content > button {
        font-size: 1rem !important;
    }
}

@media screen and (max-width: 1280px) {
    .content {
        width: 60%;
    }

    .content > a {
        font-size: 1rem;
    }

    .navbarContainer {
        .container {
            padding: 0 1rem;

            a,
            .content {
                display: none;
            }

            .connectButtonMobile {
                display: block;
                @include btn.btn;
                @include btn.primary;
                height: 2rem;
                font-size: 1rem;
                padding: 0 1rem;
            }

            .bars {
                display: block;
                cursor: pointer;
            }
        }

        .optionsMobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100vh;
            background-color: colors.$background;
            padding: 0rem 2rem;
            padding-bottom: 2rem;
            border-bottom: 0.1rem solid colors.$medium-gray;
            filter: drop-shadow(0 5rem 2rem colors.$black-alpha);
            z-index: 100;

            a {
                font-family: fonts.$amarilloUsaf;
                color: colors.$medium-gray;
                font-size: 1.5rem;
                border-bottom: 0.1rem solid colors.$medium-gray;
                padding: 1.5rem 0rem;
            }
        }
    }
}
