@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;

.container {
    width: 100%;
    height: 10rem;
    padding: 0 20%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: colors.$background;
    filter: drop-shadow(0 -0.05rem 0.2rem colors.$black-alpha);

    > .optionsDesktop,
    > .socialDesktop,
    > .otherDesktop {
        height: 100%;
        padding-top: 2rem;
        width: 16rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    h3 {
        font-size: 1rem;
        font-family: fonts.$courierBold;
        margin-bottom: 1.1rem;
    }

    a {
        > img {
            max-width: 2.1rem;
            padding-right: 0.2rem;
        }
        margin-bottom: 1rem;
        font-size: 1rem;
        font-family: fonts.$courierRegular;
        cursor: pointer;
    }

    > .socialMobile {
        > .social {
            width: 9rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > img {
                cursor: pointer;
            }
        }
    }

    .socialMobile,
    .socialDesktop {
        .nftstudios {
            margin-top: 1rem;
            font-family: fonts.$courierRegular;
            a {
                font-family: fonts.$courierBold;
            }
        }
    }

    .socialDesktop {
        img {
            padding: 0 0.2rem;
        }
    }

    .title {
        margin: 0 !important;
    }

    .SubscribeFooter {
        margin: 1rem 0;
        height: 2.8rem;
        width: 15rem;
    }

    .subscribeMobile,
    .optionsMobile,
    .socialMobile {
        display: none;
    }
}

@media screen and (max-width: 1920px) {
    .container {
        padding: 0 10rem;

        > .optionsDesktop,
        > .socialDesktop,
        > .otherDesktop {
            width: 16rem;
        }

        h3 {
            margin-bottom: 1rem;
        }

        h3,
        a {
            font-size: 1rem;
        }

        .SubscribeFooter {
            width: 15rem;
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 1440px) {
    .container {
        padding: 0 8rem;

        > .optionsDesktop,
        > .socialDesktop,
        > .otherDesktop {
            width: 12rem;
        }

        h3,
        a {
            font-size: 0.8rem;
        }

        .SubscribeFooter {
            width: 12rem;
            font-size: 0.8rem;
        }
    }
}

@media screen and (max-width: 1280px) {
    .container {
        padding: 0 3rem;

        > .optionsDesktop,
        > .socialDesktop,
        > .otherDesktop {
            width: 12rem;
        }

        h3,
        a {
            font-size: 0.8rem;
        }

        .SubscribeFooter {
            width: 12rem;
            font-size: 0.8rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 2rem 3rem;

        h3 {
            font-family: fonts.$courierBold;
            font-size: 1rem;
            margin-top: 1rem;
            margin-bottom: 0;
        }

        a {
            margin-bottom: 0;
        }

        > .optionsDesktop,
        > .socialDesktop,
        > .otherDesktop {
            display: none;
        }

        > .optionsMobile {
            display: flex;
            width: 100%;
            margin-bottom: 1rem;

            > div {
                display: flex;
                flex-direction: column;
                width: 100%;

                a {
                    margin-top: 1rem;
                    font-size: 0.9rem;
                    font-family: fonts.$courierRegular;
                }
            }
        }

        > .subscribeMobile,
        > .socialMobile {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1rem;
        }

        > .socialMobile {
            > .social {
                margin-top: 1rem;
                width: 10rem;

                > img {
                    width: 2.3rem;
                }
            }
        }

        .SubscribeFooter,
        .searchContainer {
            width: 100%;
            margin-bottom: 0;
        }
    }
}
