.componentList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;

    .componentList__item {
        width: 15rem;
        height: 15rem;
    }
}
