@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.subtitle {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    @include responsives.resolution__tablet {
        flex-direction: column;
        align-items: flex-start;
    }

    > button,
    > a {
        margin-left: 1.5rem;
    }

    @include responsives.resolution__tablet {
        > button,
        > a {
            margin-left: 0;
            margin-bottom: 1rem;
        }
    }

    h2 {
        font-size: fonts.$h2-size;
        font-family: fonts.$amarilloUsaf;
        margin: 2rem 0;

        @include responsives.resolution__tablet {
            font-size: 1.875rem;
        }
    }
}
