.logo {
    display: none;
}

@media screen and (max-width: 1280px) {
    .logo {
        display: block;
        max-width: 100%;
        height: auto;
        align-self: baseline;
        margin-bottom: 2rem;
    }
}
