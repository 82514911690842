@use "@scss/responsives" as responsives;
@use '@scss/buttons' as btn;
@use '@scss/mixins' as mixins;

.body {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 100%;
    height: 45rem;
}

.information__module_mobile {
    display: none;
}

@include responsives.resolution__laptop__m {
    .body {
        justify-content: center;
        height: auto;
    }

    .information__module_mobile {
        @include mixins.flex_container(column, center, center);
        width: 100%;

        .goto__zone {
            @include btn.btn;
            @include btn.primary;
            width: fit-content;
        }
    }
}
