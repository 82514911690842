@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.nonPayloadContainer {
    display: flex;
    flex-direction: column;
    flex-basis: 25% !important;
    margin-bottom: 2rem;
    filter: opacity(55%);

    @include responsives.resolution__mobile {
        flex-basis: 100% !important;
        margin-bottom: 1rem;
    }

    @include responsives.resolution__laptop__s {
        flex-basis: 33% !important;
    }

    img {
        max-width: 100%;
    }
}

.payloadContainer {
    @extend .nonPayloadContainer;

    &::before {
        background-color: colors.$red;
        position: absolute;
        color: colors.$white;
        font-family: fonts.$amarilloUsaf;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        width: 100%;
        margin-top: 7rem;
        height: 3.5rem;
        left: 0;
        z-index: 100;
        filter: opacity(85%);
    }
}
