@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.templateBox {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__items {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        gap: 2rem;

        a {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            span {
                font-family: fonts.$courierBold;
                margin-left: 0.5rem;
            }

            img {
                width: 14rem;
            }
        }

        &.isTicket {
            a {
                img {
                    width: 28rem;
                }
            }
        }
    }
}

@include responsives.resolution__mobile {
    .templateBox {
        &__items {
            justify-content: center;
        }
    }
}
