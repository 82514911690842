.planetMintHome {
    min-height: 90vh;
    background-image: url("../../assets/images/planetMint/background.jpg");
    background-size: cover;
    background-position: center;
    color: #ffffff;
    text-align: center;

    h1 {
        margin-bottom: 1.5rem;
    }
}
