@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.body {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 100%;

    li {
        list-style: none;
    }
}

.apartmentsList {
    @include mixins.flex_container(row, space-between, flex-start);
    margin-top: 7rem;
    max-width: 50rem;
    gap: 3rem;

    .room {
        h2 {
            font-weight: 200;
            color: colors.$white;
            font-size: 1.5rem;

            hr {
                background-color: colors.$white;
                height: 0.1rem;
            }
        }

        .apartments {
            @include mixins.flex_container(column, center, center);

            .apartment {
                margin-top: 2.5rem;
                max-width: 75%;

                p {
                    color: colors.$white;
                    margin-bottom: 0.5rem;
                }

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

.rightInfo {
    @include mixins.flex_container(column, center, center);
    width: 20rem;
    height: fit-content;
    padding: 2rem;
    margin-left: 3rem;
    background-image: url(~@assets/images/starmap/legend_frame.png);
    background-size: 100% 100%;
    color: colors.$white;

    h2 {
        width: fit-content;
        font-size: 2.3rem;
        font-weight: 200;
        background-color: transparent;
        border: none;
        border-bottom: 0.13rem solid white;
        margin-bottom: 1.3rem;
    }

    p {
        margin-top: 1rem;
        font-size: 1.3rem;
        font-family: fonts.$amarilloUsaf;
    }

    img {
        max-width: 100%;
    }
}

@include responsives.resolution__laptop__m {
    .rightInfo {
        display: none;
    }

    .body {
        @include mixins.flex_container(row, center, center);
    }
}

@include responsives.resolution__laptop__s {
    .apartmentsList {
        margin-top: 5rem;
    }
}

@include responsives.resolution__mobile {
    .apartmentsList {
        flex-direction: column;
        margin-top: 3rem;

        .room .apartments .apartment {
            max-width: 80%;
        }
    }
}
