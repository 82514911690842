@use "@scss/buttons" as btn;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.header__title {
    h1 {
        font-family: fonts.$amarilloUsaf;
        font-size: fonts.$h1-size;
        margin-top: 0;
        margin-bottom: 2rem;

        @include responsives.resolution__tablet {
            font-size: 1.875rem;
        }
    }

    span {
        display: inline-block;
        word-wrap: break-word;
        font-family: fonts.$courierBold;
        font-size: 1.375rem;
        margin-bottom: 2rem;

        @include responsives.resolution__tablet {
            font-size: 0.875rem;
        }
    }
}

.header__data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @include responsives.resolution__mobile {
        flex-direction: column;
    }

    .header__data__item {
        flex: 1;

        > div {
            margin-bottom: 2rem;

            span {
                font-family: fonts.$courierBold;
                font-size: 1.375rem;
                display: inline-block;
                word-wrap: break-word;
                margin-bottom: 1rem;
            }

            p {
                font-family: fonts.$courierRegular;
                font-size: 1.375rem;
            }

            @include responsives.resolution__tablet {
                span,
                p {
                    font-size: 0.875rem;
                }
            }
        }
    }
}

.header__buttons {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 3rem;

    @include responsives.resolution__mobile {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    a {
        width: 15rem;
    }

    a.button {
        @include btn.btn;
        @include btn.primary;

        &.disabled {
            @include btn.primaryDisabled;
        }
    }
}
