@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;

.rocketDetail__button {
    margin-top: 2rem;
}

.mediaRocket > span,
.mediaRocket > h3,
.rocketContainer > span {
    font-family: fonts.$courierRegular;
}

.rocketContainer > h2 {
    font-family: fonts.$courierBold;
}

.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    b {
        font-family: fonts.$courierBold;
    }

    .rocketView {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .rocketContainer,
        .mediaRocket {
            width: 50%;
            min-width: 25rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 4rem;
        }

        .rocketContainer {
            > h2 {
                font-size: fonts.$h2-size;
                margin-bottom: 1rem;
            }

            > img {
                width: 30rem;
                margin-top: -1rem;
                margin-left: -3.5rem;
            }

            > span {
                font-size: 1rem;
                margin: 0.2rem 0;
            }
        }

        .mediaRocket {
            margin-top: 3rem;
            > h3 {
                margin-top: 2rem;
                margin-bottom: 0.2rem;
                font-size: 1rem;
            }

            > span {
                margin: 0.2rem 0;
                font-size: 1rem;
            }

            > div {
                display: flex;
                justify-content: space-around;
                align-items: center;

                > img {
                    max-width: 15.625rem;
                }

                > video {
                    max-width: 15.625rem;
                    margin-right: 1rem;
                }

                > iframe {
                    height: 28.125rem;
                    width: 15.625rem;
                }
            }
        }
    }

    .rocketView {
        > h1 {
            width: 100%;
            text-align: start;
            font-family: fonts.$amarilloUsaf;
            font-size: fonts.$h1-size;
            margin-bottom: 1rem;
        }
    }

    .downloadTwitterBanner {
        margin-top: 2rem;
        a {
            font-family: fonts.$courierBold;
            color: colors.$blue;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        .rocketView {
            > h1 {
                font-size: 1.87rem;
                margin-bottom: 2rem;
            }
        }

        .rocketView {
            .rocketContainer {
                min-width: 20rem;

                > img {
                    max-width: 100%;
                    margin-top: -1rem;
                    margin-left: -2rem;
                }

                > h2 {
                    font-size: 1.5rem;
                    margin: 1.3rem 0 0.8rem 0;
                }
            }

            .rocketContainer,
            .mediaRocket {
                width: 100%;
                > span {
                    font-size: 0.8rem;
                }
            }

            .mediaRocket {
                margin-bottom: 2rem;
                overflow-x: auto;
                min-width: 20rem;
                margin-bottom: 2rem;

                > h3 {
                    font-size: 0.9rem;
                }

                > div {
                    display: flex;

                    > iframe {
                        height: 25rem;
                        width: 50%;
                    }

                    > img {
                        max-height: 25rem;
                        width: 50%;
                        margin: 0rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .container {
        .rocketView {
            .rocketContainer {
                margin-bottom: 0;
            }

            .mediaRocket {
                > div {
                    flex-direction: column;

                    > img {
                        max-width: 100%;
                        max-height: initial;
                        width: initial;
                    }
                }
            }
        }
    }
}
