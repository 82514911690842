@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;

.subscribeContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
        height: 100%;
        width: 100%;
        background-color: colors.$white;
        color: colors.$black;
        font-family: fonts.$courierRegular;
        padding-left: 0.8rem;
        padding-right: 3.8rem;
        border-radius: 0.438rem;
        font-size: 1rem;
        border: none !important;
    }

    > button {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.3rem;
        width: 3rem;
        height: 1.8rem;
        position: absolute;
        right: 0;
        margin-right: 0.5rem;
        border-radius: 2rem;
        border: none;
    }

    .notSent {
        background-image: url("../../../../../../assets/images/utils/arrow.png");
        background-color: colors.$gray;
        cursor: pointer;
    }

    .error {
        background-image: url("../../../../../../assets/images/utils/cross.png");
        background-color: colors.$red;
    }

    .sent {
        background-image: url("../../../../../../assets/images/utils/check.png");
        background-color: colors.$green-matte;
    }
}

@media screen and (max-width: 1440px) and (min-width: 1024px) {
    .subscribeContainer {
        input {
            font-size: 0.8rem;
        }
    }
}
