@use "@scss/responsives" as responsives;
@use '@scss/buttons' as btn;
@use '@scss/mixins' as mixins;

.body {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 100%;
    position: relative;
    height: 45rem;
}

.zone__container {
    width: 50rem;
    position: relative;
}

.zone {
    @include mixins.flex_container(row, flex-start, flex-start);
    padding-top: 7rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 0;

    &__rescaled {
        transform-origin: top;
        position: relative;
        width: 50rem;

        &__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            z-index: 0;
        }

        &__hitbox {
            transform: translate(-50%, -50%);
            position: absolute;
            border-radius: 3.125rem;
            cursor: pointer;
        }
    }
}

.information__module_mobile {
    display: none;
}

@include responsives.resolution__laptop__m {
    .body {
        height: auto;
        justify-content: center;
        padding-bottom: 1rem;
        overflow: hidden;
    }

    .zone__container {
        width: auto;
    }

    .zone {
        @include mixins.flex_container(row, center, center);
        padding-top: 0;
        position: static;
        left: 0;
        transform: none;
        height: 22rem;
        width: 100%;
        overflow: hidden;

        &__rescaled {
            transform-origin: center;
            transform: scale(0.33) !important;
        }
    }

    .information__module_mobile {
        @include mixins.flex_container(column, center, center);
        width: 100%;

        .goto__system {
            @include btn.btn;
            @include btn.primary;
            z-index: 10;
            width: fit-content;
        }
    }
}
