@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.metadata__unorganized {
    margin-top: 3rem;
    width: 100%;
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
}

@include responsives.resolution__desktop__m {
    .metadata__unorganized {
        margin-top: 1.5rem;
        gap: 2rem;
    }
}

@include responsives.resolution__mobile {
    .metadata__unorganized {
        @include mixins.flex_container(column, center, center);
    }
}
