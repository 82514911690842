@use "@scss/responsives" as responsives;

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > hr {
        margin: 3rem 0;
    }

    .container__components {
        width: 100%;

        > div {
            overflow: hidden;
        }
    }
}

@include responsives.resolution__tablet {
    .container {
        > h1 {
            font-size: 1.875rem;
        }
    }
}
