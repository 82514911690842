@use "@scss/responsives" as responsives;

.button__centered {
    display: flex;
    justify-content: center;
    width: 100%;
}

@include responsives.resolution__tablet {
    a {
        font-size: 1rem !important;
    }
}
