@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.pageContainer__container {
    > hr {
        margin: 3rem 0;
    }

    p {
        line-height: 1.5rem;
        font-size: 1.125rem;
        font-family: fonts.$courierRegular;
    }
}

@include responsives.resolution__laptop__s {
    .pageContainer__container {
        > hr {
            margin: 1.5rem 0;
        }
    }
}

@include responsives.resolution__mobile {
    .pageContainer__container {
        > h1 {
            margin-bottom: 1.5rem;
            font-size: 1.87rem;
        }
    }
}
