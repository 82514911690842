@use "@scss/responsives" as responsives;

.backToTop {
    a {
        position: fixed;
        left: 50%;
        margin-left: 31rem;
        bottom: 1.25rem;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 1s, opacity 1s linear;
        cursor: pointer;
        z-index: 999;

        @include responsives.resolution__laptop__s {
            margin-left: 36%;
        }

        @include responsives.resolution__tablet {
            margin-left: 39%;
        }

        @include responsives.resolution__mobile {
            margin-left: 30%;
        }

        img {
            width: 4rem;
            height: 4rem;

            @include responsives.resolution__tablet {
                width: 3.5rem;
                height: 3.5rem;
            }

            @include responsives.resolution__mobile {
                width: 3.3rem;
                height: 3.3rem;
            }
        }
    }

    .show {
        visibility: visible;
        opacity: 1;
        transition: opacity 1s linear;

        @include responsives.resolution__laptop__s {
            opacity: 0.6;
        }
    }
}
