@use "@scss/responsives" as responsives;
@use '@scss/fonts' as fonts;

@include responsives.resolution__8k {
    .home__container {
        min-height: 90vh;
        background-image: url("./../../assets/images/home/sky.png");
        background-size: contain;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .home__content {
        width: 100%;
        color: #ffffff;
        padding: 9rem 1rem 4rem 1rem;

        &__container {
            max-width: 62.5rem;
            margin: auto;

            h1 {
                font-size: 2.5rem;
                margin-bottom: 1.5rem;
                text-align: center;
            }

            p {
                font-family: "courier-regular";
                margin-bottom: 2rem;
            }
        }
    }

    .learn__more__button {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }

    .home__player__container {
        margin: auto;
        width: 100%;
        max-width: 85rem;
        padding: 1.5rem 4rem 5rem 4rem;
        margin-bottom: 5rem;
    }

    .home__starmap__container {
        p {
            line-height: 1.75rem;
        }

        h2 {
            margin-bottom: 2rem;
        }

        h3 {
            font-family: fonts.$courierBold;
            font-size: 1.75rem;
            margin-bottom: 0.75rem;
        }

        .subSections {
            display: flex;

            img {
                max-width: 100%;
            }

            > div {
                flex-basis: 50%;
            }

            .text {
                padding: 0 2.25rem;

                p {
                    height: 6.5rem;
                }
            }

            .linkContainer {
                text-align: center;
            }
        }
    }
}

@include responsives.resolution__laptop__l {
    .home__player__container {
        max-width: 70rem;
        padding: 1.5rem 0 5rem 0;

        > div {
            height: 600px !important;
        }
    }

    .home__starmap__container {
        .subSections {
            flex-direction: column;

            .text p {
                height: 5rem;
            }
        }
    }
}

@include responsives.resolution__laptop__s {
    .home__player__container {
        padding: 1.5rem 0 0 0;
        margin-bottom: 2.5rem;
    }
}

@include responsives.resolution__mobile {
    .home__starmap__container {
        .subSections .text p {
            height: 100%;
        }
    }
}
