/// Flex container shortcut.
///
/// @param {string} $direction
///   flex-direction value
/// @param {string} $justify
///   justify-content value
/// @param {string} $align
///   align-items value
@mixin flex_container($direction, $justify, $align) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

/// Scrollbar shortcut.
///
/// @param {number} $width
///   width value
/// @param {string} $thumb-color
///   thumb color value
/// @param {string} $track-color
///   track color value
@mixin scrollbar($width, $thumb-color, $track-color) {
    ::-webkit-scrollbar {
        width: $width;
    }

    ::-webkit-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: 2rem;
    }

    ::-webkit-scrollbar-track {
        background-color: $track-color;
        border-radius: 2rem;
    }
}
