@use "@scss/colors" as colors;
@use '@scss/buttons' as btn;
@use '@scss/fonts' as fonts;

.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -5rem;

    > h1 {
        width: 100%;
        text-align: start;
        font-family: fonts.$amarilloUsaf;
        font-size: fonts.$h1-size;
        margin-bottom: 4rem;
    }

    > div > div > div {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        > h1 {
            margin-bottom: 1.5rem;
            font-size: 1.87rem;
        }
    }
}
