@use '@scss/buttons' as btn;
@use '@scss/colors' as colors;

.backButton {
    margin-bottom: 2rem;

    button {
        background-color: colors.$backButton-gray;
        border-color: colors.$backButton-gray;
        color: colors.$white;
        margin-top: 0;

        &:hover {
            background-color: colors.$white;
            color: colors.$backButton-gray;
        }
    }
}
