@use "@scss/responsives" as responsives;
@use '@scss/colors' as colors;

.mintingInProgress {
    color: colors.$white;
    text-align: center;

    img {
        width: 37.5rem;
        padding-bottom: 1rem;
    }

    .transactionInProgress {
        display: none;
    }

    &.stuffMintingInProgress {
        img {
            max-width: 20rem;
            width: 100%;
            margin-bottom: 2rem;
        }

        .transactionInProgress {
            display: block;
            font-weight: 400;
            margin-bottom: 2rem;
        }
    }

    @include responsives.resolution__mobile {
        img {
            width: 20.625rem;
        }
    }
}
