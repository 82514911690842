@use "@scss/responsives" as responsives;

.irlMeetups__container {
    display: flex;
    flex-direction: column;

    p {
        margin: 2rem 0 2.5rem 0;
    }

    img {
        max-width: 100%;
    }
}

.irlMeetups__buttonsbar {
    display: flex;
    justify-content: space-evenly;
}

@include responsives.resolution__laptop__s {
    .irlMeetups__buttonsbar {
        justify-content: center;
        flex-wrap: wrap;
        gap: 1rem;
    }
}
