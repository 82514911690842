@use "@scss/responsives.scss" as responsives;
@use "@scss/buttons" as btn;

.modelBox {
    &.expand {
        width: 100%;
        height: 100%;
    }

    &.default {
        width: 100%;
        height: 100%;
    }

    model-viewer {
        height: inherit;
        width: inherit;
        position: sticky;
    }

    .frame {
        width: 100%;
        height: 100%;
        position: relative;
        margin-bottom: 1.5rem;
        cursor: pointer;
        background-image: url("~@assets/images/stuff/grid-and-frame.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}
