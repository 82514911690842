@use '@scss/colors' as colors;
@use "@scss/responsives" as responsives;

.scratcherSuccessPage {
    color: colors.$white;

    h1 {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
        font-weight: 200;
    }

    h3 {
        margin-top: 4rem;
        margin-bottom: 1rem;
        font-weight: 400;
    }

    hr {
        width: 100%;
        height: 0.25rem;
        color: #797979;
        background-color: #797979;
        border: none;
        margin-bottom: 3rem;
    }
}

@include responsives.resolution__tablet {
    .scratcherSuccessPage {
        p {
            font-size: 1rem;
        }
    }
}

@include responsives.resolution__mobile {
    .scratcherSuccessPage {
        p {
            font-size: 0.85rem;
        }
    }
}
