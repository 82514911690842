@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

@mixin collectionItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    @include responsives.resolution__mobile {
        flex-basis: 100% !important;
    }

    a {
        text-align: center;
    }

    img {
        max-width: 100%;
    }

    p {
        font-family: fonts.$courierBold;
        text-transform: capitalize;
        padding-left: 2.5rem;
    }
}

.collectionContainer__two {
    @include collectionItem;
    flex-basis: 50% !important;
}

.collectionContainer__three {
    @include collectionItem;
    flex-basis: 33% !important;
}

.collectionContainer__four {
    @include collectionItem;
    flex-basis: 25% !important;
}

.collectionContainer__five {
    @include collectionItem;
    flex-basis: 20% !important;
}

.item__patch {
    img {
        max-width: 80% !important;
    }
}

.item__ticket__used {
    text-align: right !important;
    img {
        max-width: 35%;
        margin-right: 0.5rem;
    }
}
