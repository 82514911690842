@use '@scss/colors' as colors;

.tooltip {
    max-height: 3.8rem;
    padding-top: 0.3rem;
    background-color: colors.$background;
    opacity: 1 !important;
    border-radius: 0 !important;
    border: 0.188rem solid colors.$blue-space;
    z-index: 100;

    h3,
    p {
        z-index: 100;
        position: relative;
    }

    h3 {
        background-color: colors.$background;
        font-size: 1.5rem !important;
        font-weight: 200;
    }

    p {
        background-color: colors.$background;
        font-size: 1rem !important;
        font-weight: 200;
    }

    > div {
        width: 1.25rem;
        height: 1.25rem;
        margin-bottom: -0.48rem;
        z-index: 0;
        border-right: 0.188rem solid colors.$blue-space;
        border-bottom: 0.188rem solid colors.$blue-space;
    }
}
