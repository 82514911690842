@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.phaseIHero__container {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 4rem 0 6rem 0;

    .videoContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        > iframe {
            width: 20.375rem;
            height: 36rem;
        }

        > h2 {
            display: none;
            padding: 1rem 0;
        }
    }

    .textContainer {
        display: flex;
        flex-direction: column;
        justify-content: left;

        > p {
            padding-top: 2rem;
            line-height: 1.75rem;
            font-size: 1.125rem;
            font-family: fonts.$courierRegular;
        }
    }

    .buttonContainer {
        margin-top: 3rem;
        @include responsives.resolution__laptop__s {
            margin-top: 0;
        }
    }
}

@include responsives.resolution__laptop__s {
    .phaseIHero__container {
        flex-direction: column;
        padding-top: 0rem;

        .textContainer {
            margin-top: 2rem;
            padding-left: 0;

            > p {
                &:nth-child(3) {
                    margin-bottom: 2rem;
                }
            }
        }

        .videoContainer {
            flex-direction: column;

            iframe {
                width: 100%;
                height: 36rem;
            }
        }
    }
}

@include responsives.resolution__mobile {
    .phaseIHero__container {
        margin-top: 1rem;
    }
}
