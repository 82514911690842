@use "@scss/responsives" as responsives;

.press__container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    h4 {
        margin-bottom: 1.5rem;
    }

    p {
        margin-bottom: 2rem;
    }

    img {
        max-width: 50%;
        margin: 0;
        object-fit: cover;
    }
}

.text__button__container {
    padding: 2.5rem 0;
}

.press__button {
    div {
        margin: 0;
        padding: 0;
    }
}

@include responsives.resolution__laptop__s {
    .press__container {
        flex-direction: column;

        img {
            max-width: 100%;
            margin: 0;
            object-fit: cover;
        }

        > div {
            padding: 0;
        }
    }
}
