@use '@scss/mixins' as mixins;
@use "@scss/responsives" as responsives;

.stuffContainer {
    margin-bottom: 3rem;

    .stuffDetails {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        row-gap: 2rem;

        .stuffItem {
            @include mixins.flex_container(column, center, center);

            h3 {
                align-self: flex-start;
            }

            .stuffImage {
                max-width: 23rem;
                width: 100%;
            }

            &__bottom {
                @include mixins.flex_container(row, space-between, center);
                margin-top: 2rem;

                p {
                    font-weight: 700;
                    width: 10rem;
                }

                .detailsButton {
                    margin: 0;
                }
            }
        }
    }
}

@include responsives.resolution__mobile_s {
    .stuffContainer {
        .stuffDetails {
            .stuffItem {
                &__bottom {
                    @include mixins.flex_container(column, center, center);

                    p {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}
