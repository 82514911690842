@use 'colors' as colors;
@use 'fonts' as fonts;

@mixin btn($fontSize: 1.3rem, $padding: 0.5rem 1rem) {
    padding: $padding;
    font-size: $fontSize;
    border: none;
    cursor: pointer;
    font-family: "Amarillo-USAF";
    text-decoration: none;
    border-radius: 2rem;
    text-align: center;
}

@mixin primary {
    border: 0.2rem solid colors.$red;
    background-color: colors.$red;
    color: colors.$white;
    &:hover {
        border: 0.2rem solid colors.$white;
        background-color: colors.$white;
        color: colors.$red;
    }
}

@mixin link {
    border: none;
    background-color: transparent;
    font-size: 1.1rem;
    font-family: fonts.$courierBold;
    text-decoration: underline;
    color: colors.$blue;
    cursor: pointer;
}

@mixin primaryDisabled {
    border-color: colors.$disabled-primary;
    background-color: colors.$disabled-primary;
    pointer-events: none;
}

@mixin secondary {
    border: 0.2rem solid colors.$red;
    background-color: colors.$transparent;
    color: colors.$red;
    &:hover {
        border: 0.2rem solid colors.$disabled-primary;
        color: colors.$disabled-primary;
        background-color: colors.$transparent;
    }
}

@mixin tertiary {
    border: 0.2rem solid colors.$white;
    background-color: colors.$white;
    color: colors.$black;
    &:hover {
        background-color: colors.$black;
        color: colors.$white;
    }
}

@mixin quaternary {
    border: 0.2rem solid colors.$blue-space;
    background-color: colors.$transparent;
    color: colors.$blue-space;
}

@mixin secondaryDisabled {
    border: 0.2rem solid colors.$disabled-secondary;
    background-color: colors.$disabled-secondary;
    cursor: none;
    pointer-events: none;
}
