@use "@scss/responsives" as responsives;

.requiredReadingAndViewing__container {
    margin-bottom: 8rem;

    h3 {
        margin-bottom: 2rem;
    }

    .columns__container {
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;

        > div {
            display: flex;
            flex-basis: 33%;
            flex-direction: column;
            justify-content: space-between;

            h5 {
                margin-top: 1rem;
            }
        }

        img {
            max-width: 100%;
        }

        iframe {
            height: 30rem;
        }
    }

    .buttonContainer {
        margin-top: 3rem;
        text-align: center;
    }
}

@include responsives.resolution__laptop__s {
    .requiredReadingAndViewing__container {
        .columns__container {
            flex-direction: column;
            height: auto;

            img {
                padding-top: 1 rem;
            }

            > div {
                width: 100%;
            }
        }

        .videoContainer {
            iframe {
                width: 100%;
            }
        }
    }
}
