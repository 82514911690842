.planetMintParis {
    background-image: url("./../../assets/images/utils/galaxyBackground.jpg");
    background-size: contain;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #ffffff;
}
