@use "@scss/responsives" as responsives;

.collectionDetail {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 6rem;
}

@include responsives.resolution__laptop__s {
    .collectionDetail {
        gap: 4rem;
    }
}

@include responsives.resolution__mobile {
    .collectionDetail {
        gap: 3rem;
    }
}
