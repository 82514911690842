@use "@scss/buttons" as btn;
@use "@scss/fonts" as fonts;
@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

.primary {
    @include btn.btn;
    @include btn.primary;
    align-self: center;
    margin-top: 2rem;
    &:disabled {
        @include btn.primaryDisabled;
    }
}

@include responsives.resolution__laptop__s {
    .primary {
        margin-top: 1rem;
    }
}

@include responsives.resolution__tablet {
    .primary {
        @include btn.btn(1rem, 0.5rem 0.7rem);
    }
}
