@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.metadata__category {
    &_name {
        margin-top: 3rem;
        font-size: 1.7rem;
        font-weight: bold;
        font-style: italic;
        text-transform: capitalize;
    }

    &_list {
        margin-top: 3rem;
        width: 100%;
        display: grid;
        gap: 3rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

@include responsives.resolution__desktop__m {
    .metadata__category {
        &_name {
            font-size: 1.5rem;
        }

        &_list {
            margin-top: 1.5rem;
            gap: 2rem;
        }
    }
}

@include responsives.resolution__tablet {
    .metadata__category {
        &_name {
            font-size: 1.4rem;
        }
    }
}

@include responsives.resolution__mobile {
    .metadata__category {
        &_list {
            @include mixins.flex_container(column, center, center);
        }
    }
}
