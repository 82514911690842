@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;
@use "@scss/buttons" as btn;

.boxDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > h1 {
        text-align: start;
        font-family: fonts.$amarilloUsaf;
        font-size: fonts.$h1-size;
        margin-bottom: 1rem;
    }

    .mainSection {
        display: flex;
        gap: 3rem;

        h3 {
            font-family: fonts.$courierBold;
            font-size: 1.65rem;
            margin-bottom: 0.75rem;
        }

        p {
            line-height: 1.75rem;
        }

        .boxDetails__description {
            width: 50%;
            min-width: 25rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            > h2 {
                font-family: fonts.$courierBold;
                font-size: fonts.$h2-size;
                margin-bottom: 1rem;
            }

            > img {
                width: 30rem;
                margin-top: -1rem;
                margin-left: -3.5rem;
            }

            > span {
                font-family: fonts.$courierRegular;
                font-size: 1rem;
                margin: 0.2rem 0;
            }

            .boxDetails__button {
                margin-top: 2rem;
            }

            .isStuffImage {
                margin-left: -1rem;
            }
        }

        .boxDetails__mona {
            padding-top: 1rem;

            img {
                max-width: 100%;
            }

            .buttonSection {
                margin-top: 1rem;
                display: flex;

                .buttonContainer {
                    flex: 1 1 50%;
                    text-align: end;
                }
            }
        }

        .boxDetails__model {
            height: 27.5rem;
            width: 35rem;
            margin-top: -1.2rem;

            .textSection {
                padding-left: 0.5rem;
            }
        }
    }
}

@include responsives.resolution__laptop__s {
    .boxDetails {
        > h1 {
            font-size: 1.87rem;
            margin-bottom: 2rem;
        }

        .mainSection {
            @include mixins.flex_container(column, center, center);

            .boxDetails__description {
                min-width: 20rem;

                > h2 {
                    font-size: 1.5rem;
                    margin: 1.3rem 0 0.8rem 0;
                }

                > img {
                    max-width: 100%;
                    margin-left: -2rem;
                }

                > span {
                    font-size: 0.8rem;
                }
            }

            .boxDetails__mona {
                padding-top: 0;

                .buttonSection {
                    .buttonContainer {
                        flex: 1 1 30%;
                    }
                }
            }

            .boxDetails__model {
                margin-top: 0;
                width: 100%;
                height: 72vw;
                margin-bottom: 4rem;

                .textSection {
                    padding-left: 6vw;

                    h3 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@include responsives.resolution__mobile {
    .boxDetails {
        .mainSection {
            flex-direction: column;

            .boxDetails__mona {
                .buttonSection {
                    flex-direction: column;

                    .buttonContainer {
                        margin-top: 1rem;
                    }
                }
            }

            .boxDetails__model {
                margin-bottom: 6rem;
            }
        }
    }
}
