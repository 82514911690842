.zine {
    max-width: 60rem;
    margin: 10rem auto;
    text-align: center;
    img {
        max-width: 100%;
        margin-bottom: 2.5rem;

        &:first-child,
        &:last-child {
            max-width: 50%;
        }
    }
}
