.badgeUsed {
    min-height: 90vh;
    background-image: url("../../../../assets/images/utils/galaxyBackground.jpg");
    background-size: cover;
    background-position: center;
    color: #ffffff;
    text-align: center;

    a {
        margin-bottom: 1.5rem;
        padding: 0.5rem 2.5rem;
    }

    p {
        text-align: left;
        margin: 3rem auto;
        max-width: 45rem;
    }

    .galaxy {
        img {
            max-width: 22rem;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        gap: 2rem;
    }
}
