@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.rightColumn__container {
    @include mixins.scrollbar(0.3rem, colors.$space-station-scroll-primary, colors.$space-station-scroll-secondary);
    @include mixins.flex_container(row, flex-end, flex-start);
    width: 19rem;
    height: 100%;
    margin-right: 1rem;
}

.body {
    height: 45.5rem;
    width: 100%;
    min-width: 15rem;
    padding: 2rem 3rem 0 2.3rem;
    background-image: url(~@assets/images/starmap/zone_select_frame.png);
    background-size: 100% 100%;

    h2 {
        width: fit-content;
        font-weight: 200;
        color: colors.$white;
        font-size: 2rem;
        margin-bottom: 2rem;
        border-bottom: 0.13rem solid white;
        white-space: nowrap;
    }

    > ul {
        @include mixins.flex_container(column, space-between, space-between);
        height: 37.5rem;
        min-width: 10rem;
        overflow-y: auto;
        padding-right: 0.7rem;
        font-family: fonts.$amarilloUsaf;
        scroll-behavior: smooth;

        > li {
            margin-bottom: 1.5rem;
            list-style-type: none;

            > h3 {
                font-weight: 200;
                color: colors.$white;
                text-decoration: underline;
                font-size: 1.8rem;
                margin-top: 1.2rem;
                margin-bottom: 0.5rem;
                text-transform: uppercase;

                &:nth-child(1) {
                    margin-top: 0;
                }
            }

            > ul {
                font-size: 1.5rem;
                color: colors.$blue-space;
            }

            a {
                font-size: 1.5rem;
                color: colors.$blue-space;

                &:hover {
                    color: colors.$white;
                }
            }
        }
    }
}

@include responsives.resolution__desktop__m {
    .rightColumn__container {
        margin-right: 0.5rem;
        width: 18rem;
    }

    .body {
        padding: 2rem 3rem 0 2rem;

        > ul {
            > li {
                > h3 {
                    font-size: 1.5rem;
                }

                > a,
                > ul {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@include responsives.resolution__laptop__m {
    .rightColumn__container {
        display: none;
    }
}
