@use "@scss/responsives" as responsives;

.marsRocks {
    margin: 3rem 0 6rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 3rem;
    grid-column-gap: 1.5rem;

    img {
        max-width: 100%;
    }

    .rockBottom {
        margin-top: 1rem;
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        button {
            background-color: transparent;
            border: solid 0.125rem #ffffff;
            border-radius: 1.25rem;
            cursor: pointer;
            width: 2rem;
            height: 2rem;

            &.unchecked {
                background-color: gray;
            }
        }

        p {
            text-transform: capitalize;
            margin: 0;
        }
    }
}

@include responsives.resolution__mobile {
    .marsRocks {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 3rem;
    }
}
