@use '@scss/mixins' as mixins;
@use "@scss/responsives.scss" as responsives;

.modal {
    height: 38vw;
    @include mixins.flex_container(column, center, center);
    background-image: url("~@assets/images/stuff/background-grid.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: multiply;
    background-color: #ffffff;
    background-size: cover;
    width: 85vw;
    margin: calc((100vh - 38vw) / 2) auto;
    border: 2px solid black;
}
