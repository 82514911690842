@use "@scss/responsives.scss" as responsives;
@use "@scss/buttons" as btn;

.expandButton {
    @include btn.link;
    position: absolute;
    right: 5%;
    top: 12%;
    width: 2rem;
    height: 2rem;
    color: black;
    z-index: 1;
    font-size: 1.5rem;

    &.hideButton {
        z-index: 0;
    }

    @include responsives.resolution__laptop__s {
        right: 9%;
        top: 10%;
        font-size: 4vw;
    }

    @include responsives.resolution__mobile {
        right: 7%;
    }
}
