@use "@scss/responsives" as responsives;
@use '@scss/colors' as colors;

.title {
    max-width: 50rem;
    width: 100%;
    position: absolute;
    text-align: start;
    color: colors.$white;

    h1 {
        font-weight: 200;
        font-size: 2.8rem;
        margin-bottom: 1.5rem;
    }

    p {
        font-style: italic;
    }
}

@include responsives.resolution__desktop__m {
    .title {
        width: 50%;
    }
}

@include responsives.resolution__laptop__m {
    .title {
        position: static;
        width: 100%;
        text-align: center;

        h1 {
            margin-bottom: 0.8rem;
        }

        br {
            display: none;
        }
    }
}
