@use '@scss/buttons' as btn;
@use '@scss/animations' as animations;
@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;

.fadeIn {
    @include animations.fadeIn;
}

.primary {
    @include btn.btn;
    @include btn.primary;
}

.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .screenTitle {
            font-family: fonts.$amarilloUsaf;
            font-size: 3.5rem;
            margin-bottom: 3rem;
        }

        .search {
            width: 50%;
            margin-bottom: 3rem;
            position: relative;
            height: 100%;
            display: flex;
            align-items: flex-start;

            > img {
                position: absolute;
                z-index: 10;
                margin: 0.8rem 1rem;
            }

            > input {
                width: 100%;
                height: 2.8rem;
                border-radius: 0.5rem;
                border: none;
                font-family: fonts.$courierRegular;
                font-size: 1rem;
                padding-left: 3rem;
                position: relative;
            }

            .submitSearch {
                border: none;
                background-image: url("../../assets/images/utils/arrow.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1rem;
                color: colors.$black;
                width: 3rem;
                height: 1.8rem;
                position: absolute;
                right: 0;
                margin: 0.5rem 0.5rem;
                border-radius: 2rem;
                background-color: colors.$gray;
                cursor: pointer;
            }
        }

        .contact {
            width: 100%;
            text-align: center;
            margin-bottom: 3rem;

            > button {
                padding: 0.5rem 2rem !important;
            }
        }

        .accordion {
            width: 100%;
            margin-bottom: 2rem;

            h5 {
                font-family: fonts.$courierBold;
                font-size: 1.5rem;
                margin: 1.5rem 0;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        > div {
            .screenTitle {
                margin-bottom: 2rem;
            }

            .search {
                width: 100%;
                margin-bottom: 3rem;
            }

            .accordion {
                h5 {
                    font-family: fonts.$courierBold;
                    font-size: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        > div {
            padding-bottom: 2rem;

            .screenTitle {
                font-size: 1.875rem;
                font-family: fonts.$amarilloUsaf;
            }

            p {
                font-size: 0.75rem;
                font-family: fonts.$courierRegular;
            }
        }
    }
}
