@use '@scss/animations' as animations;
@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;

.accordionItem {
    width: 100%;
    margin-bottom: 2rem;

    .accordionItemHeading {
        font-family: fonts.$courierBold;
        background-color: colors.$white;
        border-radius: 0.4rem;
        min-height: 3rem;
        display: flex;
        align-items: center;

        .accordionButton {
            min-height: 3rem;
            width: 100%;
            padding-left: 1rem;
            padding-right: 3rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;

            &::after {
                cursor: pointer;
                transform: rotate(60deg);
                z-index: 50;
                content: "";
                background-image: url("../../assets/images/utils/polygon.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 1.4rem;
                color: colors.$black;
                width: 3rem;
                height: 100%;
                position: absolute;
                right: 0;
                margin-right: 0.5rem;
            }
        }
    }

    .accordionItemPanel {
        font-family: fonts.$courierRegular;
        line-height: 1.5rem;
        letter-spacing: 0.05rem;
        padding: 1rem;
        position: relative;

        p {
            @include animations.fadeIn;
        }

        &::before {
            top: 0;
            right: 0;
            margin-top: -2.5rem;
            content: "";
            background-size: 1.4rem;
            width: 3rem;
            height: 2rem;
            position: absolute;
            z-index: 80;
            margin-right: 0.5rem;
            background-color: colors.$white;
            cursor: pointer;
        }

        &::after {
            z-index: 90;
            top: 0;
            right: 0;
            margin-top: -2.5rem;
            content: "";
            background-image: url("../../assets/images/utils/polygon.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1.4rem;
            width: 3rem;
            height: 1.8rem;
            position: absolute;
            margin-right: 0.5rem;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1024px) {
    .accordionItem {
        .accordionItemHeading {
            font-size: 0.8rem;
            padding: 0.5rem 0 0.5rem 0.8rem;

            .accordionButton {
                padding-left: 0;
                &::after {
                    z-index: 50;
                    background-size: 0.9rem;
                    color: colors.$black;
                    width: 1rem;
                    position: absolute;
                    right: 0;
                }
            }
        }

        .accordionItemPanel {
            font-size: 0.8rem;
            padding-bottom: 0;

            &::before {
                background-size: 1rem;
                width: 2rem;
                z-index: 80;
            }

            &::after {
                z-index: 90;
                background-size: 0.9rem;
                width: 1rem;
                height: 1rem;
            }
        }
    }
}
