@use '@scss/mixins' as mixins;
@use "@scss/responsives" as responsives;

.game {
    @include mixins.flex_container(column, center, center);
    height: 90vh;
    background-image: url("./../../assets/images/utils/background-game.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .loading {
        @include mixins.flex_container(column, center, center);

        .logo {
            width: 20rem;
            height: 22rem;
            background: url("./../../assets/images/utils/logo-game.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        .progressBar {
            border: 1px solid #3b6ec1;
            width: 141px;
            height: 18px;
            background-color: #474747;

            .progressBarInner {
                width: 0%;
                height: 17px;
                background-color: #3b6ec1;
            }
        }
    }

    .unityProvider {
        display: none;
        height: 75vh;
        margin: 0 auto;
        width: 100% !important;

        &.visible {
            @include mixins.flex_container(column, center, center);
        }
    }
}

@include responsives.resolution__laptop__s {
    .game {
        .unityProvider {
            height: 66vh;
        }
    }
}

@include responsives.resolution__mobile {
    .game {
        height: 70vh;

        .unityProvider {
            height: 45vh;
        }
    }
}
