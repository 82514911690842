@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.rocket__container {
    display: flex;
    flex-direction: column;
    flex-basis: 25% !important;
    margin-bottom: 2rem;

    @include responsives.resolution__laptop__s {
        flex-basis: 33% !important;
    }

    @include responsives.resolution__mobile {
        flex-basis: 100% !important;
        margin-bottom: 1rem;
    }

    img {
        max-width: 100%;
    }

    .rocket__description {
        margin-left: 1.8rem;

        @include responsives.resolution__mobile {
            margin-left: 2.5rem;
        }

        h5 {
            text-transform: capitalize;
            font-family: fonts.$courierBold;
            font-size: fonts.$h5-size;
        }

        span {
            font-family: fonts.$courierRegular;
        }

        @include responsives.resolution__mobile {
            span,
            h5 {
                font-size: fonts.$h5-size-mobile;
            }
        }
    }
}
