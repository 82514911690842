@use "@scss/responsives" as responsives;
@use '@scss/colors' as colors;

.publicMint {
    color: colors.$white;

    h1 {
        margin-bottom: 2rem;
    }

    .mobileOnly {
        display: none;
    }
}

@include responsives.resolution__mobile {
    .publicMint {
        h1 {
            font-size: 2rem;
        }

        p {
            font-size: 0.8rem;
        }

        .mobileOnly {
            display: block;
        }
    }
}
