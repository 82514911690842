@use "@scss/responsives" as responsives;
@use "@scss/colors" as colors;
@use '@scss/buttons' as btn;

.navbar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
        align-self: center;
        margin-top: 2rem;

        @include responsives.resolution__laptop__s {
            margin-top: 1rem;
        }

        @include responsives.resolution__tablet {
            @include btn.btn(1rem, 0.5rem 0.7rem);
        }
    }
}

@include responsives.resolution__tablet {
    .navbar__container {
        max-width: 70%;
        margin: auto;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
    }
}

@include responsives.resolution__mobile {
    .navbar__container {
        max-width: 100%;
        gap: 0.5rem;
    }
}

.navbar__selected {
    color: colors.$red;
    background-color: colors.$white;
    border-color: colors.$white;
}

.navbar__menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @include responsives.resolution__mobile {
        gap: 0.5rem;
    }

    a {
        @include responsives.resolution__mobile {
            width: 48%;
        }
    }
}

.navbar__collection {
    width: 65%;
}

.navbar__timeline {
    width: 45%;
}
