@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .rarities,
    .rarityName {
        @include mixins.flex_container(row, space-between, center);
    }

    .rarityName {
        margin-bottom: 0.5rem;

        h5 {
            font-family: fonts.$courierBold;
            margin-bottom: 0.5rem;
            font-size: 1rem;
        }
    }

    .rarities {
        span {
            font-family: fonts.$courierRegular;
            margin-bottom: 0.2rem;
            font-size: 1rem;

            @include responsives.resolution__laptop__s {
                font-size: 0.9rem;
            }
        }
    }
}
