.planetMintFinalFrontier {
    min-height: 90vh;
    background-image: url("./../../assets/images/utils/galaxyBackground.jpg");
    background-size: contain;

    color: #ffffff;
    text-align: center;

    .container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-height: 85vh;
        justify-content: center;
        gap: 3rem;

        & .centeredItems {
            align-items: center;
        }

        h1 {
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
            text-align: center;
        }
    }
}
