@use "@scss/responsives" as responsives;

.howItWorks {
    :global {
        .__react_modal_image__modal_container {
            background-color: #e0e9f0e6;
        }

        .__react_modal_image__caption {
            display: none;
        }

        .__react_modal_image__header {
            background-color: initial;
        }

        .__react_modal_image__icon_menu {
            svg {
                filter: brightness(0%);
            }
        }

        .__react_modal_image__medium_img {
            background-color: transparent;
        }
    }
}

@include responsives.resolution__mobile {
    .howItWorks {
        h1 {
            font-size: 2.5rem;
        }

        .desktop {
            display: none;
        }
    }
}
