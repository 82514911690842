@use '@scss/colors' as colors;

.invalidSerialNumber {
    margin-top: 5rem;

    .title {
        margin-bottom: 0.5rem;
        color: colors.$red;
        font-weight: 700;
    }

    .purchaseButton {
        margin-top: 2rem;
    }
}
