@use '@scss/buttons' as btn;
@use '@scss/fonts' as fonts;

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > div {
        > .primary {
            @include btn.btn;
            @include btn.primary;
        }
    }

    > h1,
    span {
        margin-bottom: 3rem;
    }

    > h1 {
        font-family: fonts.$amarilloUsaf;
        font-size: fonts.$h1-size;
    }

    > span {
        font-family: fonts.$courierBold;
        font-size: 1.875rem;
    }

    > .btnContainer {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        padding-right: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    .container {
        > h1 {
            font-family: fonts.$amarilloUsaf;
            font-size: 1.875rem;
        }

        > h2 {
            font-family: fonts.$courierBold;
            font-size: 0.875rem;
        }
    }
}
