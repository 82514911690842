@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;
@use '@scss/mixins' as mixins;
@use "@scss/responsives" as responsives;

.container {
    @include mixins.flex_container(column, center, flex-start);

    h1 {
        font-size: fonts.$h1-size;
        font-family: fonts.$amarilloUsaf;
        margin-bottom: 2rem;
    }

    h4 {
        margin-top: 1.5rem;
        font-family: fonts.$courierBold;
        font-size: fonts.$h4-size;
    }

    a {
        font-family: fonts.$courierBold;
        color: colors.$blue;
        text-decoration: underline;
    }

    p {
        font-size: 1.118rem;
        font-family: fonts.$courierRegular;
        line-height: 1.875rem;
        margin: 1rem 0;

        span {
            text-decoration: underline;
        }

        ul {
            padding: 0 3rem;

            li:not(:last-child) {
                padding-bottom: 1rem;
            }
        }
    }
}

@include responsives.resolution__laptop__s {
    .container {
        h1 {
            font-size: fonts.$h1-size-mobile;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1rem;
        }
    }
}

@include responsives.resolution__mobile {
    .container {
        h1 {
            font-size: fonts.$h2-size-mobile;
        }

        p {
            font-size: 0.75rem;

            ul {
                padding: 0 2rem;
            }
        }
    }
}
