@use '@scss/fonts' as fonts;
@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

h2,
h3,
h4 {
    font-family: fonts.$amarilloUsaf;
    margin: 0;
}

h2 {
    font-size: fonts.$h2-size;
}

h3 {
    font-size: fonts.$h3-size;
}

h4 {
    font-size: fonts.$h4-size;
}

h5 {
    font-family: fonts.$courierBold;
    font-size: fonts.$h5-size;
}

@include responsives.resolution__tablet {
    h2 {
        font-size: fonts.$h2-size-mobile;
    }

    h3 {
        font-size: fonts.$h3-size-mobile;
    }

    h4 {
        font-size: fonts.$h4-size-mobile;
    }

    h5 {
        font-size: fonts.$h5-size-mobile;
    }
}

.heading__gray {
    color: colors.$gray;
}
