@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.legendMobile__container {
    max-width: 50rem;
    width: 100%;
    display: none;
    flex-direction: column;

    hr {
        width: 100%;
        margin: 1rem 0;
    }
}

.legend {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    text-align: center;

    &__item__container {
        @include mixins.flex_container(row, center, center);
        width: 100%;

        div {
            @include mixins.flex_container(row, flex-start, center);
            width: 14rem;
            color: colors.$white;

            img {
                width: 4rem;
                margin-right: 1rem;
            }

            p {
                text-align: start;
                font-size: 1.5rem;
                font-family: fonts.$amarilloUsaf;
            }
        }
    }
}

@include responsives.resolution__laptop__m {
    .legendMobile__container {
        display: block;
    }
}

@include responsives.resolution__tablet {
    .legend {
        &__item__container {
            div {
                width: 11.5rem;

                img {
                    width: 3rem;
                    margin-right: 0.7rem;
                }

                p {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@include responsives.resolution__tablet {
    .legend {
        &__item__container {
            div {
                width: 10rem;

                img {
                    width: 2.5rem;
                }

                p {
                    font-size: 1.1rem;
                }
            }
        }
    }
}
