@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;

.container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    .component {
        margin-bottom: 1rem;
        width: 100%;

        > h1 {
            width: 100%;
            text-align: start;
            font-family: fonts.$amarilloUsaf;
            font-size: fonts.$h1-size;
            margin-bottom: 4rem;
        }

        span,
        p {
            font-family: fonts.$courierRegular;
        }

        .componentContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-bottom: 4rem;

            img {
                max-width: 35rem;
                transform: translateY(-13%) translateX(-15%);
                -webkit-transform: translateY(-13%) translateX(-15%);
            }

            h2 {
                font-family: fonts.$courierBold;
                font-size: fonts.$h2-size;
                margin: 1.3rem 0 1rem 0;
            }

            > span {
                font-size: 1rem;
                margin: 0.2rem 0;
            }

            .componentImageContainer {
                max-height: 29rem;
            }
        }
    }
}
