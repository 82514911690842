@use '@scss/colors' as colors;

.validSerialNumber {
    margin-top: 5rem;

    .title {
        margin-bottom: 0.5rem;
        color: colors.$green;
        font-weight: 700;
    }

    .text {
        margin-bottom: 4rem;
    }

    .purchaseButton {
        margin-top: 2rem;

        &.disabled {
            border-color: colors.$disabled-primary;
            background-color: colors.$disabled-primary;
            pointer-events: none;
        }
    }
}
