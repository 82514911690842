@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;
@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;

.uninhabitablePlanetsList__container {
    @include mixins.scrollbar(0.3rem, colors.$space-station-scroll-primary, colors.$space-station-scroll-secondary);
    @include mixins.flex_container(column, flex-start, flex-start);
    height: 50%;
    width: 100%;
    padding: 2rem;
    padding-bottom: 1.5rem;
    margin-bottom: 2.3rem;
    background-image: url(~@assets/images/starmap/legend_frame.png);
    background-size: 100% 100%;

    h2 {
        font-weight: 200;
        color: colors.$white;
        text-decoration: underline;
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    ul {
        overflow-y: auto;
        width: 100%;

        li {
            @include mixins.flex_container(row, flex-start, center);
            margin-bottom: 0.8rem;
            opacity: 0.5;
            color: colors.$white;
            cursor: pointer;

            img {
                margin-right: 1rem;
                width: 3rem;
            }

            p {
                font-size: 1.7rem;
                font-family: fonts.$amarilloUsaf;
                cursor: default;
                margin-bottom: 0.4rem;
            }
        }
    }
}

@include responsives.resolution__desktop__m {
    .uninhabitablePlanetsList__container {
        h2 {
            font-size: 1.7rem;
            margin-bottom: 1rem;
        }

        ul {
            li {
                img {
                    margin-right: 0.7rem;
                    width: 2rem;
                }

                p {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
