@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

.buttonTimeline {
    margin-top: 2.5rem;

    @include responsives.resolution__tablet {
        margin-top: 1rem;
    }

    &:hover {
        background-color: colors.$timeline-gray;
        border: 0.2rem solid colors.$timeline-gray;
        color: colors.$white;
    }
}
