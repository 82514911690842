@use "@scss/mixins" as mixins;
@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;
@use "@scss/buttons" as btn;

.serialNumberInput {
    .serialNumberForm {
        margin-top: 5rem;
        @include mixins.flex_container(column, center, center);

        input {
            height: 2.7rem;
            max-width: 25rem;
            width: 100%;
            background-color: colors.$white;
            color: colors.$black;
            font-family: fonts.$courierRegular;
            padding: 0 0.8rem;
            border-radius: 0.438rem;
            font-size: 1rem;
            border: none !important;
            margin-top: 1rem;
        }

        .buttonBox {
            margin-top: 2rem;
            display: flex;
            align-items: center;
            gap: 2rem;
            font-family: fonts.$amarilloUsaf;
            font-size: 2.7rem;

            button {
                cursor: pointer;
                border-radius: 2rem;
                width: 2.5rem;
                height: 2.5rem;
                border: none;
                font-family: fonts.$amarilloUsaf;
                font-size: 1.8rem;
                line-height: 0;
                background-color: colors.$red;
                color: #ffffff;

                &:disabled {
                    background-color: #838383;
                    color: #aeaeae;
                }
            }
        }

        .submitButton {
            &.isDisabled {
                @include btn.primaryDisabled;
            }
        }
    }
}
