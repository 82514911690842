@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.container {
    flex: 0 0 33.33%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0.5rem 0;

    @include responsives.resolution__laptop__s {
        margin: 0;
    }

    @include responsives.resolution__mobile {
        flex: 0 0 50%;
    }

    > h5 {
        font-family: fonts.$courierBold;
        margin-bottom: 0.5rem;
        font-size: 1rem;
    }

    > span {
        font-family: fonts.$courierRegular;
        margin-bottom: 0.5rem;
        font-size: 1rem;

        @include responsives.resolution__laptop__s {
            margin-bottom: 1rem;
            font-size: 0.9rem;
        }
    }
}
