@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.leftColumn__container {
    @include mixins.flex_container(column, flex-start, center);
    height: 45.5rem;
    margin-left: 1rem;
    width: 19rem;
}

@include responsives.resolution__desktop__m {
    .leftColumn__container {
        margin-left: 0.5rem;
        width: 18rem;
    }
}

@include responsives.resolution__laptop__m {
    .leftColumn__container {
        display: none;
    }
}
