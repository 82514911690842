.planetMintSuccess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    min-height: 90vh;
    background-image: url("./../../assets/images/utils/galaxyBackground.jpg");
    background-size: contain;

    .mintWithCardContainer {
        text-align: center;
        margin-bottom: 3rem;
    }

    .bottom {
        display: flex;
        justify-content: center;
        gap: 2rem;
    }

    p {
        margin: 2rem 0;
    }

    hr {
        margin: 3rem 0;
    }
}
