@use '@scss/fonts' as fonts;
@use '@scss/colors' as colors;
@use "@scss/responsives" as responsives;

.BoxFilter__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;

    @include responsives.resolution__mobile {
        margin-left: 0rem;
    }

    .boxFilter__search {
        position: relative;
    }

    img {
        position: absolute;
        margin: 0.8rem 0.6rem;
        width: 1rem;
    }

    form {
        display: flex;
        gap: 1rem;

        @include responsives.resolution__mobile {
            flex-direction: column;
        }

        input {
            background-color: colors.$white;
            font-family: fonts.$courierRegular;
            border-radius: 0.438rem;
            font-size: 0.75rem;
            border: none !important;
            height: 2.6rem;
            padding-left: 2rem;
            padding-right: 3.5rem;
            width: 38rem;

            @include responsives.resolution__mobile {
                width: 20rem;
            }
        }

        button {
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1rem;
            background-image: url("~@assets/images/utils/arrow.png");
            background-color: colors.$gray;
            cursor: pointer;
            width: 2.2rem;
            right: 0;
            height: 1.6rem;
            position: absolute;
            border-radius: 2rem;
            border: none !important;
            margin: 0.5rem;
        }
    }
}
