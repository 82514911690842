@use "@scss/colors" as colors;
@use '@scss/buttons' as btn;
@use "@scss/responsives" as responsives;

.rocketFilter {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > button {
        margin: 0 2rem;
    }
}

.filterButton {
    @include btn.btn;
    border: 0.2rem solid colors.$black;
    background-color: transparent;
    color: colors.$black;
    &:hover {
        color: colors.$white;
        background-color: colors.$black;
    }
}

.filterButton.selected {
    color: colors.$white;
    background-color: colors.$black;
}

@include responsives.resolution__tablet {
    .rocketFilter {
        flex-direction: column;
        align-items: center;

        > button {
            margin: 0.75rem 0;
            width: 12rem;
        }
    }
}
