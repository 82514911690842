.milestones__container {
    img {
        max-width: 100%;
    }

    .milestones__buttonsbar {
        display: flex;
        justify-content: space-evenly;
    }
}
