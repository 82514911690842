@use '@scss/fonts' as fonts;
@use "@scss/colors" as colors;
@use "@scss/responsives" as responsives;

.mintSelection {
    margin: 3rem 0;
    display: flex;
    gap: 5rem;
    justify-content: center;

    h2 {
        font-size: 2.2rem;
        margin-bottom: 1.2rem;
    }

    .deed {
        img {
            max-width: 20rem;
        }

        div {
            margin-top: 1rem;
            display: flex;
            justify-content: center;
            gap: 2rem;
        }

        .buttonBox {
            display: flex;
            align-items: center;

            button {
                cursor: pointer;
                border-radius: 2rem;
                width: 2.5rem;
                height: 2.5rem;
                border: none;
                background-color: #838383;
                color: #aeaeae;
                font-family: fonts.$amarilloUsaf;
                font-size: 1.8rem;
                line-height: 0;

                &.active {
                    background-color: colors.$red;
                    color: #ffffff;
                }
            }

            span {
                font-size: 2.7rem;
                font-family: fonts.$amarilloUsaf;
                min-width: 1.9rem;
                text-align: center;
            }
        }

        .scratcherImage {
            max-width: 30rem;
            width: 100%;
            margin-top: -2rem;
            margin-bottom: -2rem;
        }

        .buttonLabel {
            font-family: fonts.$amarilloUsaf;
            font-size: 1.2rem;
        }
    }

    .images {
        position: relative;

        .topImage {
            z-index: 10;
        }

        .stack {
            img {
                position: absolute;
            }
        }

        .c1 {
            z-index: 9;
            left: 0.35rem;
            top: -0.35rem;
        }

        .c2 {
            z-index: 8;
            position: absolute;
            left: 0.7rem;
            top: -0.7rem;
        }

        .c3 {
            z-index: 7;
            left: 1.05rem;
            top: -1.05rem;
        }

        .c4 {
            z-index: 6;
            left: 1.4rem;
            top: -1.4rem;
        }

        .c5 {
            z-index: 5;
            left: 1.75rem;
            top: -1.75rem;
        }

        .c6 {
            z-index: 4;
            left: 2.1rem;
            top: -2.1rem;
        }

        .c7 {
            z-index: 3;
            left: 2.45rem;
            top: -2.45rem;
        }

        .c8 {
            z-index: 2;
            left: 2.8rem;
            top: -2.8rem;
        }

        .c9 {
            z-index: 1;
            left: 3.15rem;
            top: -3.15rem;
        }
    }
}

@include responsives.resolution__mobile {
    .mintSelection {
        .deed {
            .scratcherImage {
                margin-bottom: -1rem;
            }

            .buttonBox {
                button {
                    width: 3rem;
                    height: 3rem;
                    font-size: 2.5rem;
                }

                span {
                    min-width: 7rem;
                }

                &.isScratcher {
                    span {
                        font-size: 4.5rem;
                    }
                }
            }

            .buttonLabel {
                &.isScratcher {
                    font-size: 2rem;
                }
            }
        }
    }
}
