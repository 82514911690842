.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5rem;
    min-height: calc(100vh - 288px);
    overflow: hidden;

    > .children {
        padding: 3rem 0;
        width: 100%;
        max-width: 62.5rem;
    }
}

@media screen and (max-width: 1024px) {
    .container {
        > .children {
            padding: 2rem 1rem;
        }
    }
}
