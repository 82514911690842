@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.galaxy__container {
    @include mixins.flex_container(row, center, flex-start);
    width: 100%;
    background-repeat: repeat;
    background-size: contain;
    padding: 9rem 1rem 4rem 1rem;
    min-height: 90vh;

    .galaxy {
        @include mixins.flex_container(column, flex-start, center);
        width: 100%;
        max-width: 120rem;
        min-height: 100vh;
        position: relative;
    }

    &.noPadding {
        padding-top: 0rem;
    }
}

@include responsives.resolution__laptop__m {
    .galaxy__container {
        padding-top: 7.5rem;

        .galaxy {
            height: auto;
            min-height: auto;
        }
    }
}
