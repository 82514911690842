@use '@scss/fonts' as fonts;

.noItemCollection {
    width: 100%;
    display: flex;
    justify-content: center;

    > span {
        font-family: fonts.$courierRegular;
        font-size: 1.5rem;
    }
}
