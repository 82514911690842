@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;

.boxAttributes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > h2 {
        text-align: start;
        font-family: fonts.$amarilloUsaf;
        font-size: fonts.$h2-size;
        margin-bottom: 1rem;

        @include responsives.resolution__laptop__s {
            font-size: 1.87rem;
            margin-bottom: 2rem;
        }
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
    }
}
