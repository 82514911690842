@use '@scss/colors' as colors;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.assetList {
    @include mixins.flex_container(column, flex-start, center);
    margin: 5rem 0;
    max-width: 50rem;
    width: 100%;
    color: colors.$white;

    h2 {
        width: 100%;
        font-weight: 200;
        margin-bottom: 1rem;
    }

    hr {
        margin: 5rem 0;
        width: 100%;
        height: 0.1rem;
        background-color: colors.$white;
    }

    .body {
        @include mixins.flex_container(column, center, center);
        width: 100%;
    }
}

@include responsives.resolution__desktop__m {
    .assetList {
        width: 50%;
        margin: 2rem 0;

        h2 {
            margin-bottom: 0;
        }
    }
}

@include responsives.resolution__laptop__m {
    .assetList {
        width: 100%;
    }
}

@include responsives.resolution__tablet {
    .assetList {
        h2 {
            font-size: 2rem;
        }
    }
}
