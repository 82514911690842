@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;
@use '@scss/colors' as colors;

.suns__container {
    height: 100%;
}

.heading {
    color: colors.$white;
    margin-bottom: 2rem;
    width: 100%;

    h2 {
        font-weight: 200;
        font-size: 2.5rem;
    }

    hr {
        background-color: colors.$white;
        height: 0.1rem;
        margin-bottom: 0.4rem;
    }

    p {
        font-style: italic;
    }
}

.grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    &__item {
        outline: none;

        img {
            width: 6rem;
        }

        &_uninhabitable {
            opacity: 0.4;
        }
    }
}

@include responsives.resolution__desktop__m {
    .grid {
        &__item {
            img {
                width: 5rem;
            }
        }
    }
}

@include responsives.resolution__laptop__m {
    .grid {
        @include mixins.flex_container(column, center, center);

        &__item {
            img {
                width: 3rem;
            }
        }
    }

    .heading {
        margin-bottom: 1rem;

        h2 {
            font-size: 1.7rem;
            margin-bottom: 0.1rem;
        }

        p {
            font-size: 0.7rem;
        }
    }
}

@include responsives.resolution__mobile {
    .grid {
        &__item > div {
            display: none;
        }
    }

    .heading {
        height: 6.5rem;
        margin-bottom: 0;

        h2 {
            height: 3.5rem;
        }
    }
}
