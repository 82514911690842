@use "@scss/responsives" as responsives;
@use '@scss/buttons' as btn;
@use '@scss/mixins' as mixins;

.body {
    @include mixins.flex_container(row, space-between, flex-start);
    width: 100%;

    li {
        list-style: none;
    }
}

.stars__container {
    @include mixins.flex_container(row, space-between, flex-start);
    margin-top: 7rem;
    max-width: 50rem;
    width: 100%;
    height: auto;
    padding-top: 1rem;
}

.rightInfo {
    @include mixins.flex_container(column, flex-start, center);
    width: 19rem;
    height: 45.5rem;
    margin-left: 3rem;
}

.scrollto__planet {
    display: none;
}

@include responsives.resolution__desktop__m {
    .rightInfo {
        width: 18rem;
    }

    .stars__container {
        width: 50%;
    }
}

@include responsives.resolution__laptop__m {
    .rightInfo {
        display: none;
    }

    .body {
        justify-content: center;
    }

    .stars__container {
        width: 100%;
        margin: 0;
    }

    .scrollto__planet {
        @include btn.btn;
        @include btn.primary;
        display: block;
    }
}
