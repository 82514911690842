@use '@scss/colors' as colors;
@use '@scss/fonts' as fonts;
@use "@scss/responsives" as responsives;
@use '@scss/mixins' as mixins;

.stepPreview__container {
    @include mixins.flex_container(column, space-between, flex-start);
    height: 20.767rem;
    width: 100%;
    padding: 1.5rem 2rem;
    background-image: url(~@assets/images/starmap/legend_frame.png);
    background-size: 100% 100%;
    margin-bottom: 2.3rem;
}

.backBtn {
    @include mixins.flex_container(row, flex-start, center);
    cursor: pointer;
    font-family: fonts.$amarilloUsaf;
    color: colors.$white;
    font-size: 2rem;
    margin-bottom: 1rem;
    background-color: transparent;
    border-bottom: 0.13rem solid white;
    outline: none;

    &__icon {
        font-size: 1.4rem;
        margin-right: 0.5rem;
    }

    span {
        text-align: center;
    }
}

.galaxy {
    @include mixins.flex_container(row, center, center);
    width: 100%;
    height: 100%;
    min-height: 14rem;

    &__image {
        max-width: 100%;
        max-height: 100%;
        padding: 1rem;
    }
}

@include responsives.resolution__desktop__m {
    .galaxy {
        &__image {
            max-width: 12rem;
            max-height: 12rem;
        }
    }
}
