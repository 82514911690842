@use "@scss/colors" as colors;
@use '@scss/fonts' as fonts;

.container {
    width: 100%;
    height: auto;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        > h1 {
            font-size: fonts.$h1-size;
            font-family: fonts.$amarilloUsaf;
            margin-bottom: 3rem;
        }

        > h4 {
            font-family: fonts.$courierBold;
            margin-top: 2rem;
            font-size: fonts.$h4-size;
        }

        > h5 {
            margin-top: 1.5rem;
            font-family: fonts.$courierBold;
            font-size: fonts.$h5-size;
        }

        > p {
            font-size: 1.118rem;
            font-family: fonts.$courierRegular;
            line-height: 1.875rem;
            margin: 1rem 0;

            > a {
                font-family: fonts.$courierBold;
                color: colors.$blue;
                text-decoration: underline;
            }
        }

        ul {
            padding: 1rem 3rem;
        }
    }
}

@media screen and (max-width: 1024px) {
    .container {
        padding-right: 1.5rem;

        > div {
            > h1 {
                margin-bottom: 2rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .container {
        > div {
            > h1 {
                font-size: 1.875rem;
                font-family: fonts.$amarilloUsaf;
            }

            > p {
                font-size: 0.75rem;
                font-family: fonts.$courierRegular;
            }
        }
    }
}
